.svg-box1 {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: rgba(102, 92, 121, 0.25);
  display: block;
  margin: auto;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    left: 20%;
  }
}

.svg-box2 {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: #cfb9f9 25%;
}

.boxes22 {
  width: 50%;
  @include respond(phone) {
    width: 100%;
  }
}
.boxes33 {
  width: 33%;
  @include respond(tab-port) {
    width: 50%;
  }
  @include respond(phone) {
    width: 100%;
  }
}

.gga {
  &_hero {
    background-color: rgba(254, 244, 191, 0.2);
    position: relative;
    padding-top: 60px;

    @include respond(phone) {
      padding-top: 30px;
    }
    &-text {
      position: relative;
      height: calc(100vh - 80px);
      @media (max-width:600px) {
        height: 350px;
      }

      &-b {
        position: absolute;
        top: 18%;
        @include respond(phone) {
          top: 15%;
        }
      }

      h1 {
        font-size: 5em;
        font-family: $font-primary;
        padding-bottom: 20px;
        color: #073041;
        @include respond(phone) {
          font-size: 4rem;
          width: 50%;
        }
      }

      p {
        font-size: 1.2em;
        padding-bottom: 30px;
        color: #9da8b6;
      }
    }
    &-image {
      display: block;
      margin: 70px auto 0;
      width: 60%;
      @include respond(tab-land) {
        display: none;
      }
      @media (max-width:600px) {
        width: 100%;
        display: block;
      }
    }
  }

  &_flex{
    @media (min-width: 900px) {
      display: flex;
      &-half1{
        width: 50%;
      }
      &-half2{
        width: 50%;
      }
    }
    
  }

  &_container {
    // margin: 70px auto 20px;
    // max-width: 900px;

    @media (min-width: 601px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_cardview {
    border: 1px solid #f1f5fc;
    border-radius: 10px;
    margin: 10px 15px 20px;

    &-box {
      width: 100%;
      height: 150px;
      background: #cfb9f9;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      display: relative;
    }

    &-text {
      padding: 20px 15px;

      &-section {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;
        &-1 {
          width: 150px;
          margin-bottom: 10px;
        }
        span {
          display: inline-block;
          font-size: 1em;
        }
      }
      .circle-box {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        background: #cfb9f9;
      }
      h4 {
        font-size: 1.3em;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
}
.svg-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
