.loading{
    height: 100vh;
    width: 100%;
    background: #773dd3;
    mix-blend-mode: normal;
    backdrop-filter: blur(14px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000000;

    &_container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }


    &_img{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute
    }
}

.lds-img {
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 40px 50px 50px 40px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 104px;
  height: 104px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
