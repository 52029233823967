.main {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0px;
  margin: 0 auto;
}
.formImage,
.formInput {
  width: 45%;
}
.formImage > img {
width: 100%;
}
.formInput > div {
  margin-bottom: 30px;
}
.formInput h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  color: #1f1035;
}
.formInput p {
  font-size: 16px;
  line-height: 22px;
  color: #535353;
  display: flex;
  align-items: flex-start;
}
.formInput p img{
  margin-right: 13px;
}
.formInput label {
  display: block;
  width: 100%;
}
.formInput input {
  width: 100%;
  height: 52px;
  border: 1px solid #848484;
  padding: 0px 20px;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 15px;
}
.formInput input::placeholder{
  font-weight: 400;
}
.formInput button {
  width: 100%;
  height: 52px;
  background: #773dd3;
  border: 1px solid #773dd3;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  transition: 0.5s ease;
}
.formInput button img {
  width: 30px;
  height: 30px;
}
.formInput button:disabled{
  background-color: #9a9a9a;
  color: #ffffff;
  border: none;
  cursor: not-allowed;
}
@media screen and (max-width: 900px) {
  .formInput h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 768px) {
  .main{
  padding: 60px 0px 20px;

  }
  .formInput h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .main {
    flex-direction: column;
  }
  .formImage,
  .formInput {
    width: 100%;
  }
  .formImage{
    text-align: center;
  }
  .formImage > img {
    width: 40%;
  }
}
