.landingmentors{
    background: #F8FAFD;
    &-container{
        max-width: 1120px;
        @media screen and (max-width: 425px){
            width: 90%;   
        }
        background: #F8FAFD;
        padding: 80px 0;
        margin-left: auto;
        margin-right: auto;
        &-head{
          font-weight: 700; 
          font-size: 40px;
          line-height: 51.04px;
          text-align: center;
          color: #073041;
          margin-bottom: 8px; 
        }
        &-text{
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          color: #787676;
          margin-bottom: 80px;  
        }
        &-main{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 24px;
            .mentor{
                width: 262px;
                padding: 16px 0px;
                display: flex;
                flex-direction: column;
                align-items: center; /* Center content within each card */
                img{
                    width: 149px;
                    height: 149px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
                h4{
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 22.97px;
                  text-align: center;
                  color: #073041;
                  margin-top: 8px;  
                }
                .stack{
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: uppercase;
                    line-height: 17.86px;
                    text-align: center;
                    color: #666666;
                    margin-top: 4px;
                    margin-bottom: 4px;
                }
                .company{
                    font-weight: 500;
                    font-size: 12px;
                    text-transform: capitalize;
                    line-height: 15.31px;
                    text-align: center;
                    color: #666666;
                }
            }
        }
    }
}