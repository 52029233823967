.mode {
  &_main {
    width: 65vw;
    font-family: "Nunito Sans", sans-serif;
    &_logo {
      margin-top: 20px;
      margin-left: 100px;
      margin-bottom: 10px;
      @media (max-width: 700px) {
        margin-left: 30px;
      }
    }
    @media (max-width: 769px) {
      width: 100%;
    }
  }
}

.content {
  text-align: center;
  width: 542px;
  padding: 50px;
  margin: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(120, 118, 118, 0.05);
  margin-bottom: 50px;

  @media (max-width: 680px) {
    width: 80%;
    padding: 40px;
  }
  h3 {
    color: #1d1e2c;
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #787676;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    padding-bottom: 48px;
  }
  h5 {
    color: #1d1e2c;
    font-family: Nunito Sans;
    font-size: 19.716px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;
  }
  &_selectFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    
    @media (max-width: 680px) {
    flex-direction: column;
    }
    &_select:hover {
      background: $color-collaborative-purple;
    }

    &_select {
      display: flex;
      width: 201px;
      height: 201px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 13.144px;
      background: #fff;
      box-shadow: 0px 1.64297px 6.57189px 0px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      // margin: auto;
      
      @media (max-width: 680px) {
        width: 200px;
        padding: 30px;
      }
      
    }
  }
}
