.virtual_container{
    box-sizing: border-box;
    background-color: rgba(119, 61, 211, 1);
    display: flex;
    justify-content: space-between;
    padding: 54px 113px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(255, 255, 255, 1);
    @media(min-width:1800px){
        padding: 54px 300px;
        font-size: 14px;
    }
    @media(max-width:1200px){
        padding: 54px 60px;
        font-size: 14px;
    }
    @media(max-width:900px){
        padding: 54px 40px;
        font-size: 12px;
    }
    @media(max-width:600px){
        padding: 54px 10px;
    }
    &_info{
        width: 632px;
        h1{
            font-family: $font-primary;
            font-size: 48px;
            font-weight: 700;
            color: rgba(255, 255, 255, 1);
            line-height: 54.54px;
            padding-top: 10px;
            padding-bottom: 16px;

            @media(max-width:900px){
                font-size: 38px;
            }
            @media(max-width:600px){
                text-align: center;
                font-size: 32px;
            }
        }
        p{
            padding-bottom: 40px;
        }
        button{
            border: 1.5px solid $color-inspiring-purple;
            border-radius: 8px;
            padding: 16px 58px;
            font-family: $font-secondary;
            font-size: 18px;
            line-height: 18px;
            color: $color-inspiring-purple;
            &:hover{
                background: $color-inspiring-purple;
                color: #fff;
                border: 1.5px solid #fff;
            }
            @media(max-width:600px){
                display: block;
                margin: 0 auto;
            }
        }
    }
    &_div{
        display: flex;
        gap: 8px;
        margin-bottom: 76px;
        @media(max-width:600px){
            flex-direction: column;
            align-items: center;
        }
    }
    &_label{
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 5px 10px;
        border-radius: 18px;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 8px;
        @media(max-width:600px){
            width: 300px;
            justify-content: center;
        }        
    }
    @media(max-width:1200px){
        &_girl > img{
            max-width: 100%;  
          }
          &_girl {
              width: 80%;
          }
    }
    @media(max-width:600px){
        &_girl {
            display: none;
        }
    }
    
}
