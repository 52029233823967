.boxes44 {
  width: 50%;
  margin-right: 20px;
  border-radius: 20px;

  @include respond(phone) {
    width: 100%;
  }
}

.boxes55 {
  width: 50%;
  margin-right: 20px;
  border-radius: 20px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.background1 {
  background: url("../images/background.png");
  background-repeat: no-repeat;
  margin: 50px 0;
}

.white-link {
  color: white;
  font-size: 1.1em;
  text-decoration: underline;
}

.white-link:hover {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.gua {
  p {
    font-size: 1.1em;
  }
  &_hero {
    background-color: rgba(220, 205, 244, 0.2);
    // height:calc(100vh - 80px);
    padding-top: 40px;
    position: relative;
    @include respond(phone) {
      padding-top: 30px;
    }
    &-text {
      position: relative;
      height: calc(100vh - 80px);

      &-b {
        position: absolute;
        top: 18%;
        @include respond(phone) {
          top: 15%;
        }
      }

      h1 {
        font-size: 5em;
        padding-bottom: 20px;
        font-family: Georgia;
        color: #073041;
        @include respond(phone) {
          font-size: 4rem;
          width: 50%;
        }
      }

      p {
        font-size: 1.2em;
        padding-bottom: 20px;
        color: #9da8b6;
      }
    }
    &_search {
      width: 100%;
      padding: 15px 140px 15px 10px;
      font-size: 1.1em;
      border-radius: 7px;
      border: 1px solid white;
      display: block;
      @media (min-width: 992px) {
        width: 90%;
      }

      &-box {
        position: relative;
        box-sizing: border-box;
      }
      &-button {
        position: absolute;
        top: 7px;
        right: 10px;
        border: 1px solid #773dd3;
        background: #773dd3;
        color: white;
        border-radius: 7px;
        padding: 8px 15px;
      }
    }
    &-image {
      position: absolute;
      bottom: 0;
      width: 35%;
      @include respond(tab-land) {
        display: none;
      }
    }
  }

  &_highlight {
    @media (min-width: 601px) {
      display: flex;
      height: 500px;
    }
    margin-top: 40px;

    &-box1 {
      background: url("../images/julia-cameron.png");
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 601px) {
        height: 500px;
        margin-bottom: 20px;
      }
    }
    &-box2 {
      background: url("../images/pixabay007.png");
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 601px) {
        height: 500px;
      }
    }
    &-box3 {
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      height: 100%;
      width: 100%;
      color: white;
      position: relative;
    }
    &-box4 {
      position: absolute;
      bottom: 40px;
      left: 20px;
      right: 20px;

      h3,
      a {
        font-family: Georgia;
      }
    }
  }

  &_cardview {
    border: 1px solid #f1f5fc;
    border-radius: 10px;
    margin: 10px 15px 30px;
    background-color: white;
    height: 400px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 1190px) {
      height: fit-content;
    }


    p {
      color: #787676;
      padding-bottom: 20px;
      box-sizing: border-box;
    }

    span {
      font-size: 1em;
      font-family: Georgia;
      padding-top: 40px;
      color: #773dd3;
      font-weight: bold;
      position: absolute;
      bottom: 16px;
      
    }
    &-box {
      height: 150px;
      background: #cfb9f9;
      border-radius: 10px;
      margin: 10px 10px 0;
      box-sizing: border-box;
      overflow: hidden;
      display: relative;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-text {
      padding: 20px 15px;
      h4 {
        font-family: Georgia;
        font-weight: 600;
      }
      span{
        cursor: pointer;
      }
      
    }
  }

  &_advice {
    background: #fef4bf;
    margin-top: 70px;
    padding: 0 20px;

    &-box {
      @media (min-width: 992px) {
        display: flex;
      }
      overflow-x: hidden;

      &-img {
        width: 80%;
        @media (max-width: 992px) {
          display: none;
        }
      }
      &-text {
        max-width: 400px;
        margin-top: 150px;
        @media (max-width: 992px) {
          margin: 80px auto;
          display: block;
        }
      }
      h2 {
        font-size: 3em;
        font-family: Georgia;
        font-weight: 600;
        margin-bottom: 20px;
        @include respond(phone) {
          font-size: 3.5rem;
        }
      }
      p {
        color: #9da8b6;
        font-size: 1.2em;
      }
      span {
        color: #773dd3;
      }
      button {
        color: white;
        margin-top: 15px;
        background: #773dd3;
        border: 1px solid #773dd3;
        padding: 10px 20px;
        font-size: 1.1em;
        border-radius: 10px;
      }
      .second-button{
        color: #773dd3;
        margin-top: 15px;
        margin-left: 15px;
        background: transparent;
        border: 2px solid #773dd3;
        padding: 10px 20px;
        font-size: 1.1em;
        border-radius: 10px;

      }
    }
  }
}
