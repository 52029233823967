.course {
  p {
    font-size: 1.1em;
  }

  &_hero {
    background-color: rgba(220, 205, 244, 0.2);
    // height:calc(100vh - 80px);
    padding: 50px 0 70px;
    position: relative;
    @include respond(phone) {
      padding-top: 100px;
    }
    &-text {
      position: relative;
      .dot-class{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
      }
      .dot{
        width: 13.19px;
        height: 13.19px;
        border-radius: 50%;
        background-color: $color-inspiring-purple;
      }
      .class-type{
        color: $color-inspiring-purple;
        font-size: 22.36px;
        line-height: 33.54px;
        font-weight: 600;
        font-family: $font-secondary;
        padding: 5.26px 15.79px;
        border-radius: 30px;
        border: 3.73px solid rgba(207, 185, 249, 0.25);
        background-color: rgba(207, 185, 249, 0.25);
      }
      h1 {
        font-size: 5em;
        padding-bottom: 20px;
        font-family: Georgia;
        color: #073041;
        @include respond(phone) {
          font-size: 4rem;
          width: 50%;
        }
      }

      p {
        font-size: 1.2em;
        padding-bottom: 20px;
        color: #9da8b6;
      }
    }
    &_search {
      width: 100%;
      padding: 15px 140px 15px 10px;
      font-size: 1.1em;
      border-radius: 7px;
      border: 1px solid white;
      display: block;
      @media (min-width: 992px) {
        width: 90%;
      }

      &-box {
        position: relative;
        box-sizing: border-box;
      }
      &-button {
        position: absolute;
        top: 7px;
        right: 10px;
        border: 1px solid #773dd3;
        background: #773dd3;
        color: white;
        border-radius: 7px;
        padding: 8px 15px;
      }
    }
    &-image {
      position: absolute;
      bottom: 0;
      width: 35%;
      @include respond(tab-land) {
        display: none;
      }
    }
  }

  &__btn {
    margin: 0;
    display: flex;
  }

  &_learn {
    padding: 50px 0 0;

    &-main {
      &-list {
        display: grid;
        grid-template-columns: 50px auto;

        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: 50px 400px auto;
        }
        h3 {
          font-size: 1.5em;
          font-weight: 700;
        }
        p {
          font-size: 1.2em;
        }
        .box3 {
          @media (max-width: 768px) {
            grid-column: 1 /-1;
          }
        }
      }
    }
  }

  &_wwd {
    margin-top: 80px;

    &-intro {
      h4 {
        color: #773dd3;
        font-size: 1em;
        display: none;
      }
      h2 {
        color: #073041;
        font-family: Georgia;
        font-weight: 600;
        @include respond(phone) {
          font-size: 3rem;
        }
      }
      span {
        color: #773dd3;
      }
      p {
        color: #9da8b6;
      }
    }
    &-flex {
      display: flex;
      max-width: 90%;
      justify-content: center;

      .first-div {
        margin-right: 50px;
        position: relative;
        @include respond(tab-port) {
          display: none;
        }

        &_1 {
          position: absolute;
          top: 530px;
          left: 60px;
          @include respond(tab-land) {
            display: none;
          }
        }
      }

      &-inner {
        @media (min-width: 601px) {
          display: flex;
        }
        @include respond(tab-port) {
          max-width: 100%;
        }

        .svg-box {
          width: 50px;
          height: 50px;
          border-radius: 15px;
          background: rgba(207, 185, 249, 0.2);
        }

        .info {
          width: 245px;
          margin: 30px 30px 10px;
          @include respond(tab-port) {
            margin: 40px 20px;
          }
          @include respond(phone) {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }

    &-imagebox {
      width: 400px;
      height: 600px;
      margin-top: 30px;
      border-radius: 20px;
      overflow: hidden;
      @include respond(tab-land) {
        display: none;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    &-btn {
      margin: 10px 0;
      display: flex;
    }
  }

  &_mode {
    margin-top: 80px;
    margin-bottom: 70px;

    &-intro {
      h4 {
        color: #773dd3;
        font-size: 1em;
      }
      h2 {
        color: #073041;
        font-family: Georgia;
      }
    }

    &-container {
      @media (min-width: 601px) {
        display: flex;
      }
      margin-top: 40px;

      p {
        color: #9da8b6;
      }
      h3 {
        color: #073041;
        font-family: $font-primary;
        @include respond(phone) {
          font-size: 18px;
        }
      }
      .svg-box {
        width: 50px;
        height: 50px;
        border-radius: 15px;
        background: rgba(207, 185, 249, 0.3);
        position: relative;
        img{
          position: absolute;
          top:10px;
          left:10px;
        }
      }
      .lenght1{
        @media(min-width:800px){
          padding-right:50px;
        }
      }
      .boxes {
        margin: 10px 10px 30px;
      }
      .mode-pic {
        border-radius: 20px;
        overflow: hidden;
        margin-top: 20px;
        @include respond(tab-port) {
          display: none;
        }
      }
    }

    &-quote {
      margin: 50px auto 80px;
      text-align: center;
      @media (min-width: 900px) {
        width: 850px;
      }
      img {
        display: block;
        margin: auto auto 15px;
        width: 50px;
      }
      p {
        font-size: 1.6em;
      }
      span {
        color: #9da8b6;
      }
    }

    &-intro2 {
      text-align: center;
    }

    &-tutors {
      margin: 40px 0;
      overflow: hidden;
      white-space: nowrap;
      @include respond(phone) {
        overflow: auto;
      }

      &-box {
        width: 250px;
        margin: 5px 10px;
        padding: 10px;
        box-shadow: 0px 5px 10px rgba(31, 16, 53, 0.1);
        border-radius: 10px;
        text-align: center;

        div {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #f1f5fc;
        }
        h5 {
          font-size: 1.3em;
        }
        img {
          width: 30px;
        }
      }

      div {
        display: inline-block;
      }
    }
  }

  &_purple {
    background: #5726a6;
    border-radius: 25px;
    padding: 50px;
    margin: 70px 50px 100px;
    color: white;
    @include respond(tab-land) {
      margin: 50px 0 70px;
      padding: 50px 20px;
    }
    a{
      color:white
    }

    &_txt {
      text-align: center;
    }
    p {
      font-size: 1.2em;
    }
    h1 {
      font-size: 3em;
    }
    &-main {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      @media (max-width: 700px) {
        display: block;
      }

      .grad-box{
        text-align: center;
        @media (min-width: 700px) {
          width:300px
        }
      }

      .undergrad-box{
        @media (min-width: 700px) {
          width:33%;
        }
      }

      &-boxes {
        border: 2px solid white;
        border-radius: 15px;
        margin: 15px;
        padding: 20px;
        @media (max-width: 700px) {
          margin: 30px 15px;
        }

        h4 {
          font-size: 1.3em;
        }
        span{
          font-size: 0.7em;
        }
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      text-align: center;
      @media (max-width: 400px) {
        display: block;
        margin-left: 15px;
      }
      p {
        cursor: pointer;
        margin-top: 10px;
        margin-left: 10px;
        @media (max-width: 400px) {
          margin-top: 20px;
          margin-left: 0px;
          text-align: center;
        }
      }
    }
  }
  .book-session{
    padding: 0 90px;
    @media (max-width: 1200px) {
      padding: 0 60px;
    }
    @media (max-width: 600px) {
      padding: 0 16px;
    }
    &-container{
      &-mobile{
        display: none;
        @media (max-width: 600px) {
          display: block;
          padding-top: 32px;
        }
      }
      display: flex;
      justify-content: space-between;
      border: 1px solid #F8F5FD;
      border-radius: 28px;
      padding: 0px 95.66px;
      background: #F8F5FD;
      @media (max-width: 1200px) {
        padding: 0 80px;
      }
      @media (max-width: 900px) {
        padding: 0 40px;
      }
      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h5{
        font-family: $font-primary;
        font-size: 56px;
        line-height: 41.85px;
        color: $color-inspiring-purple;
        margin-top: 71px;
        margin-bottom: 28px;
        font-weight: 700;
      }
      p{
        font-family: $font-secondary;
        font-size: 28px;
        line-height: 41.85px;
        color: $color-formgrey;
        margin-bottom: 28.7px;
        font-weight: 400;
        // width: 722.25px;
        width: 90%;

      }
      button{
        background: $color-inspiring-purple;
        padding: 19.13px 44.24px;
        border-radius: 9.57px;
        color: #fff;
        font-size: 21.52px;
        font-family: $font-secondary;
        line-height: 21.52px;
        margin-bottom: 71.15px;
        font-weight: 700;
        border: none;
        // width: 251.49px;
      }
      &-text{
          position: relative;
        img{
          position: absolute;
          top: 115px;
          right: 120px;
        }
      }
      &-pic > img{
        max-width: 100%;
      }
      &-pic {
        max-width: 80%;
        @media (max-width: 600px) {
          display: none;
        }
      }
      @media (max-width: 1200px) {
        h5{
          font-size: 36px;
        }
        p{
          font-size: 20px;
          line-height: 36.85px;
        }
        &-text{
        img{
          display: none;
        }
      }
      }
      @media (max-width: 900px) {
        h5{
          font-size: 25px;
          margin-top: 51px;
        }
        p{
          font-size: 16px;
          line-height: 30.85px;
        }
        button{
          padding: 15.13px 34.24px;
          font-size: 16.52px;
        }
        
      }
      @media (max-width: 600px) {
        h5{
          font-size: 17px;
          line-height: 26.85px;
          margin: 0px;
          text-align: center;
        }
        p{
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          margin-bottom: 16px;
        }
        button{
          padding: 15.13px 34.24px;
          font-size: 16.52px;
          display: block;
          margin: 0 auto;
          margin-bottom: 32px;
        }
        
      }
      
    }
    
  }
}
