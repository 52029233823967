*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1rem == 10px
  overflow-x: hidden;

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px
  }

  @include respond(tab-port) {
    font-size: 50%; //1rem = 8px
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12
  }
}


input:focus, select:focus, textarea:focus{
  outline: 1px solid #ccc;
  border-radius: 10px;
}