.coming {
  &__hero {
    height: 100vh;
  }

  &__container {
    display: flex;
    height: 100%;
    &--left {
      flex-basis: 50%;
      position: relative;
      @include respond(tab-port) {
        flex-basis: 60%;
      }
      @include respond(phone) {
        flex-basis: 100%;
      }

      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;

        h3 {
          font-size: 1.6rem;
          color: #773dd3;
          font-family: $font-secondary;
        }

        h1 {
          color: #073041;
          font-size: 4.2rem;
          font-family: $font-primary;
          font-weight: bold;
          @include respond(phone) {
            font-size: 3.5rem;
          }
        }

        form {
          display: block;

          label {
            display: block;
          }

          input {
            padding: 1rem;
            border: 1px solid #eff2f7;
            border-radius: 5px;
            color: #9da8b6;
            font-size: 1.6rem;
            width: 100%;
            outline: none;
            transition: all 0.2s;
            margin-top: 1rem;

            &:focus {
              border-color: #773dd3;
            }
          }

          button {
            display: block;
            background-color: #773dd3;
            font-size: 1.6rem;
            font-family: $font-primary;
            border: none;
            outline: none;
            color: $color-white;
            padding: 1rem 2rem;
            margin-top: 1.5rem;
            font-weight: normal;
            border-radius: 3px;
          }
        }
      }
    }
    &--right {
      flex-basis: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      @include respond(tab-port) {
        flex-basis: 40%;
      }
      @include respond(phone) {
        display: none;
      }

      &-hire {
        background-image: url(../images/hire.jpg);
      }

      &-nysc {
        background-image: url(../images/nysc.jpg);
      }
      &-careers {
        background-image: url(../images/careers.jpg);
      }
      &-training {
        background-image: url(../images/training.jpg);
      }
    }
  }
}
