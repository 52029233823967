/*
0 - 600px:     Phone
600 - 900px    Tablet portrait
900 - 1200px   Tablet landscape
1200 - 1800px  Big desktop

*/
/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

input:focus, select:focus, textarea:focus {
  outline: 1px solid #ccc;
  border-radius: 10px;
}

.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.layout_auth-main {
  width: 65vw;
  font-family: "Nunito Sans", sans-serif;
}
@media (max-width: 769px) {
  .layout_auth-main {
    width: 100%;
  }
}
.layout_auth-image {
  width: 35vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
}
@media (max-width: 769px) {
  .layout_auth-image {
    display: none;
  }
}
.layout_dashboard-sidebar {
  position: fixed;
  width: 20vw;
  top: 0;
  left: 0;
  bottom: 0;
  background: #F8F8F8;
  height: 100vh;
}
@media (max-width: 769px) {
  .layout_dashboard-sidebar {
    z-index: 2;
    width: 200px;
    transition: 0.5s;
    left: -210px;
  }
}
.layout_dashboard-content {
  width: 80vw;
  float: right;
}
@media (max-width: 769px) {
  .layout_dashboard-content {
    width: 100vw;
  }
}

.nav {
  background-color: transparent;
  position: relative;
  padding: 1.5rem;
  transition: all 0.3s;
  z-index: 9999;
}
.nav--fixed {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 4px 14px -6px rgba(7, 48, 65, 0.1);
  animation: fadeInDown 0.95s ease-in-out 0s 1 normal none running;
}
@media (max-width: 37.5em) {
  .nav {
    padding: 0;
  }
}
.nav__container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  padding: 0 3rem;
  padding-left: 0;
}
@media (max-width: 37.5em) {
  .nav__container {
    padding: 2rem 3rem;
    padding-right: 0rem;
  }
}
@media (max-width: 37.5em) {
  .nav__container__logo img {
    height: 10px !important;
  }
}
.nav__dropdown {
  position: absolute;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  top: 3rem;
  top: 2.2rem;
  left: -2.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.nav__dropdown--active {
  opacity: 1;
  visibility: visible;
}
.nav__dropdown--list {
  list-style-type: none;
}
.nav__dropdown--list li:first-child {
  margin-bottom: 1rem;
}
.nav__list {
  list-style-type: none;
  display: flex;
  align-items: center;
}
.nav__list--mb {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 100vh;
  background: #773dd3;
  transition: all 500ms ease-in-out;
  padding: 5rem 2rem;
  padding-top: 12rem;
  -webkit-clip-path: circle(30px at calc(100% - 65px) 65px);
          clip-path: circle(30px at calc(100% - 65px) 65px);
  visibility: hidden;
}
@media (max-width: 37.5em) {
  .nav__list {
    display: none;
  }
}
.nav__link {
  text-decoration: none;
  color: #9da8b6;
}
@media (max-width: 37.5em) {
  .nav__link {
    display: flex;
  }
}
.nav__link span {
  font-size: 1.6rem;
  font-weight: 600;
  display: inline-block;
  margin-right: 2rem;
  margin-top: 5px;
}
.nav__link:hover {
  text-decoration: underline;
  color: #9da8b6;
}
@media (max-width: 37.5em) {
  .nav__link {
    font-size: 3rem;
    margin-bottom: 5rem;
    font-weight: bold;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 4rem;
  }
}
.nav__item {
  font-size: 1.5rem;
  font-weight: normal;
  color: #575a65;
  display: flex;
  align-items: center;
}
.nav__item:not(:last-child) {
  margin-right: 4rem;
}
@media (max-width: 56.25em) {
  .nav__item:not(:last-child) {
    margin-right: 2rem;
  }
}
@media (max-width: 37.5em) {
  .nav__item:not(:last-child) {
    margin-right: 0;
  }
}
.nav__item .mask {
  margin-left: 1rem;
  transform: translateY(2px);
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .nav__item .mask {
    display: none;
  }
}
.nav__btn {
  font-size: 1.6rem;
  font-family: Georgia;
  font-weight: 500;
  letter-spacing: 0.04em;
  transition: all 0.2s;
}
@media (max-width: 37.5em) {
  .nav__btn {
    margin-right: 0;
  }
}
.nav__btn:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.nav__btn-flex {
  display: flex;
}
.nav__btn--text {
  background-color: #773dd3;
  padding: 1.5rem 2rem;
  color: #fff;
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
}
.nav__btn--text:hover {
  color: #fff;
}
.nav__btn--text--yellow {
  background-color: #e7a300;
}
.nav__btn--text1 {
  background-color: transparent;
  padding: 1.5rem 2rem;
  color: #773dd3;
  border-radius: 5px;
  border: 2px solid #773dd3;
  font-family: "Nunito Sans", sans-serif;
}
.nav__menu {
  display: flex;
  align-items: center;
  position: relative;
}
.nav__menu .nav-tgl {
  cursor: pointer;
  margin-right: 2rem;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  text-align: center;
  z-index: 9009090909;
  display: none;
}
@media (max-width: 37.5em) {
  .nav__menu .nav-tgl {
    display: inline-block;
  }
}
.nav__menu .nav-tgl > span {
  display: inline-block;
  position: relative;
  height: 2px;
  width: 20px;
  border-radius: 1px;
  background: #293335;
  vertical-align: middle;
}
.nav__menu .nav-tgl > span:before, .nav__menu .nav-tgl > span:after {
  display: inline-block;
  position: absolute;
  content: "";
  height: 2px;
  border-radius: 1px;
  background: #293335;
  transition: all 200ms;
}
.nav__menu .nav-tgl > span:before {
  top: -8px;
  left: 3px;
  width: 18px;
}
.nav__menu .nav-tgl > span:after {
  top: 8px;
  left: 6px;
  width: 16px;
}
.nav__menu .nav-tgl:focus {
  outline: none;
  background-color: white;
  border-radius: 99999px;
}
.nav__menu .nav-tgl:hover > span:after, .nav__menu .nav-tgl:hover > span:before {
  width: 20px;
  left: 0;
}
.nav__menu--active .nav__list--mb {
  visibility: visible;
  -webkit-clip-path: circle(100%);
          clip-path: circle(100%);
}
.nav__menu--active .nav-tgl > span {
  height: 0;
}
.nav__menu--active .nav-tgl > span:after, .nav__menu--active .nav-tgl > span:before {
  top: 0px;
  left: 0;
  width: 34px;
}
.nav__menu--active .nav-tgl > span:after {
  transform: rotate(-45deg);
}
.nav__menu--active .nav-tgl > span:before {
  transform: rotate(45deg);
}

.nav__item button {
  background-color: #fff;
  padding: 2rem 2.5rem;
  border: none;
  color: #773dd3;
  font-size: 2rem;
}

.footer {
  background: #fff;
  padding: 5rem;
  padding-top: 5rem;
  padding-bottom: 3.5rem;
}
@media (max-width: 37.5em) {
  .footer {
    padding: 4rem;
    padding-top: 6rem;
  }
}
.footer__container {
  max-width: 1200px;
  margin: 0 auto;
}
.footer__links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
}
@media (max-width: 56.25em) {
  .footer__links {
    margin-bottom: 0;
  }
}
@media (max-width: 56.25em) {
  .footer__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
}
.footer__links ul:first-child {
  flex-basis: 25rem;
}
.footer__links ul li {
  margin-bottom: 2rem;
}
.footer__list {
  list-style-type: none;
}
@media (max-width: 56.25em) {
  .footer__list {
    margin-bottom: 5rem;
  }
}
.footer__accredit {
  max-width: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
}
@media screen and (max-width: 900px) {
  .footer__accredit {
    align-items: start;
  }
}
@media (max-width: 56.25em) {
  .footer__accredit {
    margin-bottom: 5rem;
  }
}
.footer__item {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 200;
  color: #787676;
  line-height: 32px;
}
@media (max-width: 37.5em) {
  .footer__item {
    line-height: 25px;
    font-size: 1.6rem;
  }
}
.footer__item--head {
  font-family: Georgia;
  font-size: 1.6rem;
  font-weight: bold;
  color: #1d1e2c;
}
.footer__item a {
  text-decoration: none;
  color: #787676;
}
.footer__item a:hover {
  text-decoration: underline;
}
.footer__text {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #073041;
}
@media screen and (max-width: 900px) {
  .footer__text {
    text-align: start;
  }
}
.footer__line {
  width: 100%;
  height: 1px;
  background-color: #e5e9f2;
  margin-bottom: 3rem;
}
@media (max-width: 56.25em) {
  .footer__line {
    margin-bottom: 5rem;
  }
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 56.25em) {
  .footer__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer__bottom > * {
    margin-bottom: 2rem;
  }
}
.footer__bottom a {
  text-decoration: none;
  font-size: 1.4rem;
  color: #787676;
}
.footer__bottom a:hover {
  text-decoration: underline;
}
.footer__bottom p {
  font-size: 1.4rem;
  color: #787676;
}
.footer__icons {
  flex-basis: 15rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 56.25em) {
  .footer__icons {
    width: 15rem;
  }
}
.footer__icons img {
  height: 2rem;
}
.footer__logo {
  display: flex;
  margin-bottom: 3rem;
  transform: translateX(-2rem);
}
.footer__logo img:first-child {
  margin-right: 1rem;
}

.modal2__bg {
  height: 100vh;
  width: 100%;
  background: rgba(48, 50, 75, 0.1);
  mix-blend-mode: normal;
  -webkit-backdrop-filter: blur(14px);
          backdrop-filter: blur(14px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000000;
}
.modal2__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50%;
  padding: 3rem 2rem;
}
@media (max-width: 37.5em) {
  .modal2__content {
    width: 90%;
  }
}
.modal2__header {
  font-family: Georgia;
  font-size: 3.5rem;
  font-weight: normal;
  color: #1f1035;
  margin-bottom: 2rem;
}
.modal2__table {
  width: 100%;
}
.modal2__table tr {
  margin-bottom: 2rem;
}
.modal2__table th {
  color: #1f1035;
  font-size: 1.4rem;
  font-family: Georgia;
  font-weight: normal;
}
.modal2__table td {
  color: #1f1035;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
}
.modal2__table td,
.modal2__table th {
  padding: 1.8rem;
}
.modal2__footer {
  color: #575a65;
  font-size: 1.6rem;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 2rem;
}
.modal2__footer a {
  color: #773dd3;
}

.infomodal {
  background-color: #fef4bf;
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 10000;
}
.infomodal_content {
  display: flex;
  justify-content: center;
  margin: 20px 20px;
}
@media (max-width: 37.5em) {
  .infomodal_content {
    margin: 10px 15px;
  }
}
.infomodal_content img {
  margin: 5px 10px;
}
.infomodal_content p {
  margin: 5px 10px 0 20px;
  font-size: 18px;
  line-height: 32.74px;
  color: #073041;
}
@media (max-width: 768px) {
  .infomodal_content p {
    margin: 5px 10px 0 10px;
    font-size: 12px;
    line-height: 25.74px;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .infomodal_content p {
    margin: 5px 10px 0 10px;
  }
}
.infomodal_content-link {
  font-size: 16px;
  margin-left: 5px;
  color: #773dd3;
}
@media (min-width: 600px) {
  .infomodal_content-link {
    display: none;
  }
}
.infomodal_content button {
  background: #773dd3;
  color: white;
  margin: 0 40px 0 0;
  padding: 4px 10px;
  border: 1px solid #773dd3;
  border-radius: 7px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .infomodal_content button {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 426px) {
  .infomodal_content button {
    margin: 10px 10px 0 0;
  }
}
@media (max-width: 37.5em) {
  .infomodal_content button {
    display: none;
  }
}
@media (max-width: 37.5em) {
  .infomodal_badge {
    display: none;
  }
}

.web3modal {
  background-color: #fef4bf;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 10000;
}
.web3modal_content {
  display: flex;
  justify-content: center;
  padding: 20px 20px;
}
.web3modal_content img {
  margin: 5px 10px;
}
.web3modal_content p {
  margin: 5px 10px 0 20px;
  font-size: 18px;
  line-height: 32.74px;
  color: #073041;
}
@media (max-width: 768px) {
  .web3modal_content p {
    margin: 5px 10px 0 10px;
    font-size: 12px;
    line-height: 25.74px;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .web3modal_content p {
    margin: 5px 10px 0 10px;
  }
}
.web3modal_content-link {
  font-size: 16px;
  margin-left: 5px;
  color: #773dd3;
}
@media (min-width: 600px) {
  .web3modal_content-link {
    display: none;
  }
}
.web3modal_content button {
  background: #773dd3;
  color: white;
  margin: 0 40px 0 0;
  padding: 4px 10px;
  border: 1px solid #773dd3;
  border-radius: 7px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .web3modal_content button {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 426px) {
  .web3modal_content button {
    margin: 10px 10px 0 0;
  }
}
@media (max-width: 37.5em) {
  .web3modal_content button {
    display: none;
  }
}
@media (max-width: 37.5em) {
  .web3modal_badge {
    display: none;
  }
}

.modal3__bg {
  height: 100vh;
  width: 100%;
  background: rgba(48, 50, 75, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000000;
}
.modal3__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 30%;
  height: 25rem;
  padding: 2rem;
  border-radius: 1rem;
}
@media (max-width: 37.5em) {
  .modal3__content {
    width: 85%;
  }
}
.modal3__content--head {
  display: flex;
  justify-content: space-between;
}
.modal3__content--svg {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}
.modal3__content--svg path {
  fill: #1f1035;
}
.modal3__content--body {
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: 600;
  color: #1f1035;
}
.modal3__content--body h1 {
  margin-bottom: 2rem;
  font-size: 4.5rem;
  color: #5726a6;
  font-family: Georgia;
}

.loading {
  height: 100vh;
  width: 100%;
  background: #773dd3;
  mix-blend-mode: normal;
  -webkit-backdrop-filter: blur(14px);
          backdrop-filter: blur(14px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000000;
}
.loading_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loading_img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.lds-img {
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 40px 50px 50px 40px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 104px;
  height: 104px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sidebar {
  padding: 0 0 0 15%;
}
@media (max-width: 769px) {
  .sidebar {
    transition: 1s;
  }
}
.sidebar .logo {
  width: 150px;
  margin: 20px 0 40px;
}
@media (max-width: 992px) {
  .sidebar .logo {
    width: 100px;
  }
}
.sidebar-portal-heading {
  color: #333333;
  font-size: 19px !important;
  margin-bottom: 30px;
}
.sidebar-portal-links {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
.sidebar-portal-links p {
  margin-top: 3px;
  margin-left: 10px;
}
.sidebar-portal .active {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
.sidebar-portal .active p {
  margin-top: 3px;
  margin-left: 10px;
  color: #333333;
}
.sidebar-portal .active path {
  fill: #333333;
}
.sidebar-portal .signout {
  margin-top: 45vh;
}

.virtual_container {
  box-sizing: border-box;
  background-color: rgb(119, 61, 211);
  display: flex;
  justify-content: space-between;
  padding: 54px 113px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: rgb(255, 255, 255);
}
@media (min-width: 1800px) {
  .virtual_container {
    padding: 54px 300px;
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .virtual_container {
    padding: 54px 60px;
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .virtual_container {
    padding: 54px 40px;
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .virtual_container {
    padding: 54px 10px;
  }
}
.virtual_container_info {
  width: 632px;
}
.virtual_container_info h1 {
  font-family: Georgia;
  font-size: 48px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  line-height: 54.54px;
  padding-top: 10px;
  padding-bottom: 16px;
}
@media (max-width: 900px) {
  .virtual_container_info h1 {
    font-size: 38px;
  }
}
@media (max-width: 600px) {
  .virtual_container_info h1 {
    text-align: center;
    font-size: 32px;
  }
}
.virtual_container_info p {
  padding-bottom: 40px;
}
.virtual_container_info button {
  border: 1.5px solid #773dd3;
  border-radius: 8px;
  padding: 16px 58px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: #773dd3;
}
.virtual_container_info button:hover {
  background: #773dd3;
  color: #fff;
  border: 1.5px solid #fff;
}
@media (max-width: 600px) {
  .virtual_container_info button {
    display: block;
    margin: 0 auto;
  }
}
.virtual_container_div {
  display: flex;
  gap: 8px;
  margin-bottom: 76px;
}
@media (max-width: 600px) {
  .virtual_container_div {
    flex-direction: column;
    align-items: center;
  }
}
.virtual_container_label {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 5px 10px;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 600px) {
  .virtual_container_label {
    width: 300px;
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  .virtual_container_girl > img {
    max-width: 100%;
  }
  .virtual_container_girl {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .virtual_container_girl {
    display: none;
  }
}

.landingmentors {
  background: #F8FAFD;
}
.landingmentors-container {
  max-width: 1120px;
  background: #F8FAFD;
  padding: 80px 0;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 425px) {
  .landingmentors-container {
    width: 90%;
  }
}
.landingmentors-container-head {
  font-weight: 700;
  font-size: 40px;
  line-height: 51.04px;
  text-align: center;
  color: #073041;
  margin-bottom: 8px;
}
.landingmentors-container-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #787676;
  margin-bottom: 80px;
}
.landingmentors-container-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}
.landingmentors-container-main .mentor {
  width: 262px;
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content within each card */
}
.landingmentors-container-main .mentor img {
  width: 149px;
  height: 149px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.landingmentors-container-main .mentor h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22.97px;
  text-align: center;
  color: #073041;
  margin-top: 8px;
}
.landingmentors-container-main .mentor .stack {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 17.86px;
  text-align: center;
  color: #666666;
  margin-top: 4px;
  margin-bottom: 4px;
}
.landingmentors-container-main .mentor .company {
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 15.31px;
  text-align: center;
  color: #666666;
}

.payment {
  padding: 27px 91px 160px 39px;
}
.payment h4 {
  color: var(--333333, #333);
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  letter-spacing: 1.62px;
}
.payment-table {
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  .payment-table {
    display: none;
  }
}
.payment-table table {
  border-collapse: collapse;
  width: 100%;
}
.payment-table thead {
  border-bottom: 1px solid rgba(120, 118, 118, 0.6);
  background: rgba(241, 245, 252, 0.15);
}
.payment-table td {
  border-bottom: 1px solid #e5e9f2;
  text-align: center;
  padding-top: 42px;
  padding-bottom: 18px;
  font-size: 16px;
  color: #909198;
}
.payment-table th {
  border-bottom: 1px solid #e5e9f2;
  text-align: center;
  padding: 12px 8px;
  font-size: 16px;
  color: #909198;
}
.payment-table th {
  font-weight: 500;
  text-transform: uppercase;
}
.payment-table p {
  font-size: 12px;
  text-transform: capitalize;
  text-align: center;
}
.payment-table span {
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
}
.payment-table-action {
  background-color: white;
  border: 1px solid #773dd3;
  position: absolute;
  padding: 10px 10px 0;
  z-index: 1;
}
.payment-table .approved {
  padding: 2px 10px;
  width: 100px;
  color: #188f32;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-table .pending {
  padding: 2px 10px;
  width: 100px;
  color: #cc9600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-table .paid {
  width: 93px;
  margin: auto;
  color: #787676;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
  line-height: 18px; /* 150% */
  border-radius: 100px;
  border: 2px solid #fff;
  background: #E9E9EA;
  pointer-events: none;
}
.payment-table .pay-now {
  width: 93px;
  margin: auto;
  color: #773dd3;
  text-align: center;
  font-size: 12px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  border-radius: 100px;
  border: 2px solid #fff;
  background: rgba(220, 205, 244, 0.5);
  cursor: pointer;
}
.payment-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .payment-mobile {
    display: block;
  }
}
.payment-mobile-container {
  margin-top: 32px;
  border-bottom: 1px solid #efefef;
}
.payment-mobile-container .details {
  color: var(--Grey, #909198);
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-mobile-container .details span {
  color: var(--text-color, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-mobile-container .details .approved {
  padding: 2px 10px;
  width: 100px;
  color: #188f32;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-mobile-container .details .pending {
  padding: 2px 10px;
  width: 100px;
  color: #cc9600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.payment-mobile .paid {
  width: 93px;
  margin: auto 0;
  color: #787676;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  line-height: 18px; /* 150% */
  border-radius: 100px;
  border: 2px solid #fff;
  background: #E9E9EA;
  margin-bottom: 8px;
  pointer-events: none;
}
.payment-mobile .pay-now {
  width: 93px;
  margin: auto 0;
  color: #773dd3;
  text-align: center;
  font-size: 12px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  border-radius: 100px;
  border: 2px solid #fff;
  background: rgba(220, 205, 244, 0.5);
  margin-bottom: 8px;
  cursor: pointer;
}

#timer {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 400;
  color: #FF3B30;
  border: 1px solid #FF3B30 !important;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 20px;
  margin: 0;
}

.assessment-container {
  width: 60%;
  height: 80vh;
  margin-right: auto;
  padding: 28px;
  margin-left: auto;
  background-color: #fff;
  z-index: 19999;
  border: 1px solid #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
@media screen and (max-width: 600px) {
  .assessment-container {
    padding: 16px;
    width: 85%;
  }
}
.assessment-container .img-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assessment-container img {
  width: 64px;
  height: 64px;
  display: block;
}
@media screen and (max-width: 600px) {
  .assessment-container img {
    width: 32px;
    height: 32px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.assessment-container-timer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.assessment-container-timer h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 32.74px;
  color: #333;
  margin: 0;
}
.assessment-container #button {
  display: block;
  margin-top: auto;
  width: 164px;
}
.assessment-container #button img {
  width: 16px;
  height: 16px;
  -o-object-fit: cover;
     object-fit: cover;
}
.assessment-container-form .question p {
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  color: #333;
}
.assessment-container-form .question .options-container .option-container {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  color: #787676;
  line-height: 24px;
}
.assessment-container-form .question .options-container .option-container input {
  display: block;
  margin: 0;
}
.assessment-container-form .question .options-container .option-container label {
  display: block;
  font-size: 16px;
  margin: 0;
}

.message-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.message-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.message-content {
  width: 588px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 28px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  z-index: 19999;
  border: 1px solid #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.message-content img {
  display: block;
  margin: auto;
}
.message-content h2 {
  width: -moz-fit-content;
  width: fit-content;
  margin: 24px auto 8px auto;
  font-weight: 600;
  font-size: 24px;
  line-height: 32.74px;
  color: #333;
}
.message-content .pass {
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
  color: #00C885;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 8px auto;
}
.message-content .fail {
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
  color: #FF3B30;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 8px auto;
}
.message-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #787676;
  text-align: center;
}
.message-content button {
  height: 52px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24.55px;
  margin: 0 !important;
}
.message-content #score {
  color: #333;
  font-weight: 700;
  line-height: 32.74px;
}
.message-content .ass-times {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .message-content {
    width: 80%;
    padding: 16px;
  }
}
.message-content button {
  width: 100%;
  box-sizing: border-box;
}

.accordion-button {
  width: 100%;
  border: none;
  background: white;
  text-align: start;
}

.loader {
  left: 50%;
  position: absolute;
  width: 40px;
}

.loader-top {
  left: 50%;
  top: 50%;
  position: fixed;
  width: 40px;
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.blur-active {
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.faq-icon_transition {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.landing__intern {
  background-color: #f1f5fc;
  margin-bottom: 10rem;
}
.landing__intern--btn {
  margin: 0 auto;
  display: flex;
  margin-left: 20px;
}
.landing__intern--text {
  font-size: 6rem;
  color: #1f1035;
  font-weight: normal;
  font-family: Georgia;
  margin: 2rem 0;
}
.landing__intern--text h1 {
  width: 70%;
}
@media (max-width: 37.5em) {
  .landing__intern--text h1 {
    width: 100%;
  }
}
.landing__intern--content {
  padding: 2rem;
  padding-bottom: 7rem;
  max-width: 1200px;
  margin: 0 auto;
}
.landing__header {
  background-color: #fff;
  padding: 1rem;
  padding-bottom: 8rem;
  position: relative;
}
.landing__header .bg-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 50rem;
}
.landing__header .st-btn {
  width: 137.29px;
  margin: auto;
}
.landing__text {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem;
  padding-bottom: 1.5rem;
  text-align: center;
}
.landing__text-below {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
@media (max-width: 600px) {
  .landing__text-below {
    display: block;
    margin: 30px auto 0;
    width: 250px;
  }
}
.landing__text-below div {
  display: flex;
}
.landing__text-below div img {
  margin: 0 10px 0 40px;
}
@media (max-width: 600px) {
  .landing__text-below div img {
    margin: 0 10px 0 0;
  }
}
.landing__text-below div h6 {
  font-size: 16px;
}
.landing__text h1 {
  font-family: Georgia;
  color: #1f1035;
  font-weight: 700;
  font-size: 6rem;
  margin-top: 8rem;
}
.landing__text h1 span {
  color: #773dd3;
}
@media (max-width: 56.25em) {
  .landing__text h1 {
    font-size: 5rem;
  }
}
@media (max-width: 37.5em) {
  .landing__text h1 {
    font-size: 3.5rem;
    margin-top: 12rem;
  }
}
.landing__text p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2rem;
  margin: 3rem 0;
  color: #9da8b6;
  line-height: 30px;
  font-weight: normal;
}
@media (max-width: 37.5em) {
  .landing__text p {
    margin: 2rem 0;
    line-height: 25px;
    font-size: 1.8rem;
  }
}
.landing__btn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.landing__btn--2 {
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
}
.landing__btn--3 {
  margin-left: auto;
}
.landing__bottom {
  padding: 14rem 5rem;
  max-width: 1200px;
  margin: 0 auto 6rem;
}
@media (max-width: 37.5em) {
  .landing__bottom {
    padding: 2rem;
  }
}
.landing__bottom--text1 {
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  margin: 0 25px;
}
@media (min-width: 900px) {
  .landing__bottom--text1 {
    width: 500px;
    margin-left: 15rem;
  }
}
.landing__bottom--text1 h1 {
  font-family: Georgia;
}
.landing__bottom--text1 p {
  font-size: 16px;
  color: #787676;
}
.landing__bottom--text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
}
@media (max-width: 37.5em) {
  .landing__bottom--text {
    display: block;
  }
}
.landing__bottom--text h2 {
  font-family: Georgia;
  width: 100vw;
  text-align: center !important;
}
.landing__bottom--text-2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}
.landing__bottom--text-2 p {
  color: #9da8b6;
}
@media (max-width: 56.25em) {
  .landing__bottom--text2 {
    width: 60%;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--text2 {
    width: 90%;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--text {
    flex-direction: column-reverse;
    align-items: initial;
  }
}
.landing__bottom--text--right {
  flex-basis: 49%;
}
@media (max-width: 56.25em) {
  .landing__bottom--text--right {
    flex-basis: initial;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--text--right {
    margin-top: 40px;
  }
}
.landing__bottom--text--left {
  text-align: center;
}
.landing__bottom--text--left img {
  height: auto;
  width: 100%;
}
@media (max-width: 56.25em) {
  .landing__bottom--text--left img {
    width: 90%;
  }
}
.landing__bottom--text h5 {
  font-size: 1.4rem;
  color: #5726a6;
  margin-top: 2rem;
}
.landing__bottom--text h1 {
  font-size: 5rem;
  color: #1f1035;
  font-weight: normal;
  font-family: Georgia;
  margin: 2rem 0;
}
@media (max-width: 56.25em) {
  .landing__bottom--text h1 {
    font-size: 4rem;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--text h1 {
    font-size: 3rem;
  }
}
.landing__bottom--text p {
  font-size: 16px;
  line-height: 3rem;
  margin-bottom: 2rem;
  color: #787676;
}
.landing__bottom--form {
  background-color: #773dd3;
  margin-top: 8rem;
  border-radius: 10px;
  text-align: center;
  padding: 10rem;
  position: relative;
}
@media (max-width: 37.5em) {
  .landing__bottom--form {
    border-radius: 5px;
    margin-bottom: 6rem;
  }
}
.landing__bottom--form img {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 37.5em) {
  .landing__bottom--form img {
    width: 8rem;
  }
}
@media (max-width: 56.25em) {
  .landing__bottom--form {
    padding: 6rem;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--form {
    padding: 2rem;
  }
}
.landing__bottom--form h2 {
  font-size: 3.7rem;
  color: #fff;
  font-family: Georgia;
  margin-bottom: 3rem;
  font-weight: normal;
}
@media (max-width: 37.5em) {
  .landing__bottom--form h2 {
    font-size: 2.2rem;
  }
}
.landing__bottom--form form input {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 0 1.2em;
  font-size: 1.4rem;
  color: #fff;
  outline: none;
  border-radius: 3px;
  width: 374px;
  height: 58px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  margin-right: 1rem;
  margin-bottom: 2rem;
}
@media (max-width: 56.25em) {
  .landing__bottom--form form input {
    width: 300px;
  }
}
@media (max-width: 37.5em) {
  .landing__bottom--form form input {
    width: 50%;
    height: initial;
    padding: 1.8rem;
  }
}
.landing__bottom--form form input::-moz-placeholder {
  color: #fff;
}
.landing__bottom--form form input::placeholder {
  color: #fff;
}
.landing__bottom--form form button {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 0 1.5rem;
  color: #773dd3;
  font-size: 1.4rem;
  font-family: Georgia;
  height: 58px;
  outline: none;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .landing__bottom--form form button {
    height: initial;
    padding: 1.8rem;
  }
}
.landing__bottom--form form button:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.landing_faq {
  margin-bottom: 100px;
  font-family: Georgia;
}
.landing_faq h1 {
  font-weight: 700;
  font-family: Georgia;
  margin: 4rem 0;
  color: #073041;
  font-size: 40px;
  line-height: 45.45px;
  text-align: center;
}
.landing_faq h3 {
  margin: 40px 0 20px;
  color: #773dd3;
}
.landing_faq h4 {
  color: #1f1035;
  font-size: 20px !important;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
@media (max-width: 500px) {
  .landing_faq h4 {
    font-size: 16px !important;
  }
}
.landing_faq p {
  font-size: 1.4rem;
  color: #787676;
  line-height: 35px;
}
.landing_faq hr {
  margin: 0px 0 20px;
}
.landing_faq .faq-number {
  color: #2196F3;
}
.landing_faq .faq-whatsapp {
  color: #773DD3;
}
.landing_faq-link {
  color: #773dd3;
}
.landing_faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landing_faq-answer {
  padding: 10px 0;
  background: #FBFCFF;
  border-radius: 8px;
  padding: 10px 10px 20px 10px;
}
.landing_faq-answer h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #787676;
  margin: 0;
}
.landing_faq-answer p {
  line-height: 24px;
}
.landing__slider {
  max-width: 100vw;
  background-color: #f1f5fc;
}
.landing__slider-text1 {
  display: block;
  margin: auto;
  max-width: 900px;
}
.landing__slider-text1 p {
  color: #787676;
  text-align: center;
  margin: 0 20px 25px 0;
}
.landing__slider-contain {
  width: 100vw;
  position: relative;
}
.landing__slider--container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex !important;
  overflow-x: scroll;
  padding: 5rem;
}
.landing__slider--container::-webkit-scrollbar {
  display: none;
}
.landing__slider--container .scroll-left {
  position: absolute;
  top: 170px;
  left: 30px;
  transform: rotate(-90deg);
  background: white;
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .landing__slider--container .scroll-left {
    width: 40px;
    left: 15px;
  }
}
@media (max-width: 700px) {
  .landing__slider--container .scroll-left {
    display: none;
  }
}
.landing__slider--container .scroll-right {
  position: absolute;
  background: white;
  border-radius: 50%;
  top: 170px;
  right: 30px;
  transform: rotate(90deg);
  cursor: pointer;
}
@media (max-width: 1000px) {
  .landing__slider--container .scroll-right {
    width: 40px;
    right: 15px;
  }
}
@media (max-width: 700px) {
  .landing__slider--container .scroll-right {
    display: none;
  }
}
.landing__slider--container .video-width {
  box-sizing: border-box;
  width: 560px;
  border-radius: 20px;
  height: 315px;
  margin-right: 30px;
}
@media (max-width: 700px) {
  .landing__slider--container .video-width {
    width: 320px;
    height: 200px;
  }
}
@media (max-width: 900px) {
  .landing__slider-desktop {
    display: none;
  }
}
.landing__slider-mobile {
  padding: 70px 0;
}
@media (min-width: 900px) {
  .landing__slider-mobile {
    display: none;
  }
}
.landing__slider-mobile iframe {
  width: 90%;
  height: 50vw;
  margin: 5vw auto;
  display: block;
}
.landing__slider--text {
  flex-basis: 50%;
  font-family: "Nunito Sans", sans-serif;
}
@media (max-width: 56.25em) {
  .landing__slider--text {
    flex-basis: 55%;
  }
}
@media (max-width: 37.5em) {
  .landing__slider--text {
    flex-basis: 90%;
    margin: 0 60px 0 20px;
  }
}
.landing__slider--text p {
  font-size: 2rem;
  line-height: 40px;
  color: #183b56;
  margin-bottom: 2rem;
  font-weight: 400;
  margin-top: 2rem;
  animation: fade-in 1s;
}
@media (max-width: 56.25em) {
  .landing__slider--text p {
    line-height: 30px;
  }
}
.landing__slider--text small {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  color: #183b56;
  font-weight: 400;
}
.landing__slider--img {
  border-radius: 9999px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(24, 59, 86, 0.1);
  height: 370px;
  width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 56.25em) {
  .landing__slider--img {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 37.5em) {
  .landing__slider--img {
    display: none;
  }
}
.landing__slider--img img {
  height: 350px;
  width: 350px;
  border-radius: 9999px;
}
@media (max-width: 56.25em) {
  .landing__slider--img img {
    height: 200px;
    width: 200px;
  }
}
.landing__dots div {
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
  background-color: rgba(115, 135, 150, 0.5);
  height: 9px;
  width: 9px;
  border-radius: 99999px;
}
.landing__dots--0 div:nth-child(1) {
  background-color: #183b56;
}
.landing__dots--1 div:nth-child(2) {
  background-color: #183b56;
}
.landing__dots--2 div:nth-child(3) {
  background-color: #183b56;
}
.landing__dots--3 div:nth-child(4) {
  background-color: #183b56;
}
.landing__cards {
  margin: 8rem 0;
  padding: 3rem;
}
@media (max-width: 56.25em) {
  .landing__cards {
    margin: 0;
  }
}
.landing__cards--container {
  max-width: 1200px;
  margin: 0 auto;
}
.landing__courses {
  margin: 10rem 0;
  margin-bottom: 0;
  padding: 6rem 3rem;
  background-color: #eff9ff;
  margin-top: 0;
}
.landing__courses--container--txt {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 2rem;
}
@media (max-width: 37.5em) {
  .landing__courses--container--txt {
    flex-direction: column;
    align-items: initial;
    padding: 0;
  }
}
.landing__courses--cards {
  max-width: 1150px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;
  margin-top: 9rem;
}
@media (max-width: 56.25em) {
  .landing__courses--cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 37.5em) {
  .landing__courses--cards {
    display: block;
  }
}
.landing__courses--card {
  background-color: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 9rem;
  padding: 2rem;
  padding-top: 0;
  border-radius: 10px;
}
.landing__courses--card h3 {
  font-family: Georgia;
  font-size: 2rem;
  color: #1f1035;
  margin-top: 1rem;
  font-weight: bold;
}
.landing__courses--card p {
  color: #9da8b6;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.4rem;
  margin: 2rem 0;
  margin-bottom: 3rem;
  line-height: 28px;
}
.landing__courses--card a {
  text-decoration: none;
  color: #773dd3;
  font-family: Georgia;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  transition: all 0.2s;
}
.landing__courses--card a:hover {
  text-decoration: underline;
}
.landing__courses--card div {
  width: 100%;
  height: 200px;
  background-color: #62b8f3;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-4rem);
  border-radius: 10px;
}
.landing__courses--container {
  max-width: 1200px;
  margin: 0 auto;
}
.landing__card--box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
@media (max-width: 56.25em) {
  .landing__card--box {
    display: block;
  }
}
.landing__card {
  text-align: center;
  padding: 5rem;
  padding-top: 7rem;
  border-radius: 22px;
  margin-bottom: 3rem;
}
@media (max-width: 37.5em) {
  .landing__card {
    border-radius: 5px;
    padding: 3rem;
    padding-bottom: 5rem;
  }
}
.landing__card h3 {
  font-size: 4rem;
  font-family: Georgia;
}
.landing__card h3 span {
  color: #e7a300;
}
.landing__card p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
  margin: 1.5rem 0;
  color: #575a65;
  line-height: 32px;
}
@media (max-width: 37.5em) {
  .landing__card p {
    line-height: 25px;
  }
}
.landing__card--1 {
  background-color: #fff6e5;
}
.landing__card--2 {
  background-color: #dccdf4;
}
.landing__card--2 h3 span {
  color: #682dc8;
}
.landing__sponsors {
  width: 100%;
  overflow: hidden;
}
.landing__sponsors--container {
  max-width: 1200px;
  margin: 6rem auto;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}
@media (max-width: 37.5em) {
  .landing__sponsors--container {
    margin: 3rem 0;
  }
}
.landing__sponsors--container img {
  height: 35px;
}
@media (max-width: 37.5em) {
  .landing__sponsors--container img {
    height: 15px;
  }
}
.landing__sponsors--container img:not(:last-child) {
  margin-right: 1rem;
}
.landing__middle {
  padding: 10rem 2rem;
}
.landing__middle--container {
  max-width: 1200px;
  margin: 0 auto;
}
.landing__middle--text {
  text-align: center;
}
.landing__middle--text h5 {
  font-size: 1.6rem;
  font-family: "Nunito Sans", sans-serif;
  color: #5726a6;
  margin-bottom: 2rem;
}
.landing__middle--text h2 {
  color: #1f1035;
  font-size: 5rem;
  font-family: Georgia;
  margin-bottom: 2rem;
}
@media (max-width: 37.5em) {
  .landing__middle--text h2 {
    font-size: 3rem;
  }
}
.landing__middle--text p {
  color: #9da8b6;
  font-size: 1.8rem;
  line-height: 35px;
}
.landing__middle--images {
  text-align: center;
}
.landing__middle--images img {
  border-radius: 99999px;
}
.landing__middle--images a {
  color: #1f1035;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 1.5rem;
  display: block;
  transition: all 0.2s;
}
.landing__middle--images a:hover {
  text-decoration: underline;
}
.landing__middle--images h5 {
  font-size: 1.2rem;
  color: #1f1035;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  margin: 1rem 0;
}
.landing__middle--images small {
  display: block;
  color: #9da8b6;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.landing__middle--images svg {
  display: block;
  margin: 0 auto;
}
.landing__middle--images--box {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5rem;
}
@media (max-width: 56.25em) {
  .landing__middle--images--box {
    grid-template-columns: 1fr 1fr;
  }
}
.landing__video video {
  height: 100%;
  width: 100%;
  outline: none;
}
@media (max-width: 37.5em) {
  .landing__video video {
    height: 100%;
  }
}
.landing__discount {
  background-color: rgba(254, 244, 191, 0.4);
  margin-top: 0rem;
  position: relative;
}
@media (max-width: 37.5em) {
  .landing__discount {
    margin-top: 0;
  }
}
.landing__discount--container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 2rem;
}
@media (max-width: 37.5em) {
  .landing__discount--container {
    display: block;
  }
}
@media (max-width: 37.5em) {
  .landing__discount--img {
    display: none;
  }
}
.landing__discount--img img {
  border-radius: 20px;
  height: auto;
  width: 400px;
  position: absolute;
  top: -5rem;
}
@media (max-width: 56.25em) {
  .landing__discount--img img {
    height: auto;
    width: 270px;
    position: initial;
    float: left;
  }
}
.landing__discount--text {
  flex-basis: 30%;
  align-self: center;
  margin-left: 50rem;
}
@media (max-width: 56.25em) {
  .landing__discount--text {
    margin-left: 3rem;
    flex-basis: 40%;
  }
}
.landing__discount--text h3 {
  color: #073041;
  font-size: 3rem;
  font-family: Georgia;
  font-weight: bold;
  line-height: 48px;
  margin: 0;
}
@media (max-width: 56.25em) {
  .landing__discount--text h3 {
    line-height: initial;
  }
}
.landing__discount--text p {
  margin: 1rem 0;
  color: #9da8b6;
  line-height: 32px;
  font-size: 1.6rem;
  font-family: "Nunito Sans", sans-serif;
}
@media (max-width: 56.25em) {
  .landing__discount--text p {
    line-height: initial;
    margin: 2rem 0;
  }
}
.landing__discover--container {
  max-width: 1200px;
  margin: 5rem auto;
  padding: 0 2rem;
  margin-bottom: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 75em) {
  .landing__discover--container {
    margin-left: 3rem;
  }
}
@media (max-width: 37.5em) {
  .landing__discover--container {
    display: block;
    margin-left: 0;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
}
.landing__discover--images {
  display: flex;
}
@media (max-width: 37.5em) {
  .landing__discover--images {
    display: none;
  }
}
.landing__discover--images-left div {
  border-radius: 10px;
  margin-bottom: 1.5rem;
  background-size: cover;
  background-repeat: no-repeat;
}
.landing__discover--images-left div:first-child {
  height: 190px;
  width: 180px;
  background-image: url(../images/Grazac-website/IMG_8350.JPG);
  background-position: left -40px bottom 0px;
  margin-left: auto;
}
@media (max-width: 75em) {
  .landing__discover--images-left div:first-child {
    height: 150px;
    width: 130px;
  }
}
.landing__discover--images-left div:last-child {
  height: 320px;
  width: 250px;
  background-image: url(../images/Grazac-website/IMG_8304.JPG);
  background-position: right 0px bottom 0px;
}
@media (max-width: 75em) {
  .landing__discover--images-left div:last-child {
    height: 260px;
    width: 210px;
  }
}
.landing__discover--images-right div {
  height: 210px;
  width: 190px;
  border-radius: 10px;
  margin-left: 1.5rem;
  background-image: url(../images/Grazac-website/IMG_8324.JPG);
  background-position: left 0px bottom 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 75em) {
  .landing__discover--images-right div {
    display: none;
  }
}
.landing__discover--list {
  flex-basis: 50%;
}
.landing__discover--list-item {
  display: flex;
}
.landing__discover--list-item div {
  margin-left: 5rem;
}
.landing__discover--list-item h2 {
  color: #9da8b6;
  font-size: 2.4rem;
  font-weight: normal;
}
.landing__discover--list-item h3 {
  color: #1f1035;
  font-family: Georgia;
}
.landing__discover--list-item p {
  font-size: 1.6rem;
  color: #787676;
  line-height: 35px;
}
@media (max-width: 37.5em) {
  .landing__discover--list-item p {
    line-height: 30px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.Vitual_hero {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 95%;
}
@media screen and (max-width: 940px) {
  .Vitual_hero {
    display: block;
    width: 90%;
  }
}
.Vitual_hero_girl > img {
  width: 100%;
}
.Vitual_hero_girl {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .Vitual_hero_girl {
    margin-top: 32px;
  }
}
.Vitual_hero h4 {
  color: var(--Main-Color, #773dd3);
  font-family: Georgia;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
}
@media screen and (max-width: 600px) {
  .Vitual_hero h4 {
    font-size: 22px;
  }
}
.Vitual_hero h1 {
  color: #073041;
  font-family: Georgia;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .Vitual_hero h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 600px) {
  .Vitual_hero h1 {
    font-size: 28px;
  }
}
.Vitual_hero p {
  color: #073041;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  padding: 32px 0;
}
@media screen and (max-width: 1024px) {
  .Vitual_hero p {
    padding: 28px 0;
  }
}
@media screen and (max-width: 600px) {
  .Vitual_hero p {
    font-size: 16px;
  }
}
.Vitual_text {
  max-width: 599px;
}
@media screen and (max-width: 940px) {
  .Vitual_text {
    text-align: center;
    align-items: center;
    margin: auto;
  }
}
.Vitual_btn {
  background-color: #773dd3;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 15px 25px;
  color: #fff;
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  outline: none;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .Vitual_btn {
    height: initial;
    padding: 1.8rem;
  }
}
.Vitual_btn:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.Vitual_countdown {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 95%;
  margin: 55px auto;
}
@media screen and (max-width: 600px) {
  .Vitual_countdown {
    font-size: 16px;
    flex-direction: column;
  }
}
.Vitual_countdown p {
  color: #073041;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Vitual_countdown p span {
  color: #073041;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 600px) {
  .Vitual_countdown p span {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .Vitual_countdown p {
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-top: 52px;
  }
}
.Vitual_countdown_container {
  display: flex;
  gap: 18px;
  align-items: center;
}
.Vitual_countdown_container_duration {
  background-color: #340080;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 10px 20px;
  color: #fff;
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  outline: none;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .Vitual_countdown_container_duration {
    font-size: 10px;
  }
}
.Vitual_class {
  background-color: #f1f5fc;
}
.Vitual_class_container {
  width: 95%;
  padding: 48px 0px 64px 0;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .Vitual_class_container {
    padding: 32px 0;
  }
}
.Vitual_class_container h1 {
  color: var(--text-color, #333);
  font-family: Georgia;
  font-size: 40px;
  font-weight: 700;
  line-height: 45.45px;
  padding-bottom: 14px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .Vitual_class_container h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 600px) {
  .Vitual_class_container h1 {
    font-size: 28px;
  }
}
.Vitual_class_container_steps {
  width: 341.33px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 32px 10px 22px 10px;
  text-align: center;
}
@media screen and (max-width: 400px) {
  .Vitual_class_container_steps {
    width: 90%;
  }
}
.Vitual_class_container_steps h4 {
  font-family: Georgia;
  font-weight: 700;
  font-size: 20px;
  line-height: 22.72px;
  color: #073041;
  padding-top: 14px;
}
.Vitual_class_container_steps p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.Vitual_class_container_stepFlex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
}
.Vitual_class_container_stepFlex .image-container > img.forward {
  max-width: 100%;
}
.Vitual_class_container_stepFlex .image-container {
  max-width: 80%;
}
.Vitual_class_container_stepFlex .down {
  display: none;
}
@media screen and (max-width: 768px) {
  .Vitual_class_container_stepFlex {
    flex-direction: column;
  }
  .Vitual_class_container_stepFlex .forward {
    display: none;
  }
  .Vitual_class_container_stepFlex .down {
    display: block;
  }
  .Vitual_class_container_stepFlex .image-container > img.down {
    max-width: 80%;
  }
  .Vitual_class_container_stepFlex .image-container {
    max-width: 80%;
  }
}
.Vitual_class_container_stepFlex button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 20px;
  background: #773dd3;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}
.Vitual_class_container_stepFlex h5 {
  color: #073041;
  font-family: Georgia;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  .Vitual_class_container_stepFlex h5 {
    padding: 10px;
  }
}
.Vitual_class_container_stepFlex p {
  color: var(--Sub-text, #787676);
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
}
@media screen and (max-width: 600px) {
  .Vitual_class_container_stepFlex p {
    align-items: center;
    font-size: 16px;
  }
}
.Vitual_slider {
  display: flex;
  background-color: #dccdf4;
}
@media screen and (max-width: 940px) {
  .Vitual_slider {
    display: block;
  }
}
.Vitual_slider_img > img {
  width: 100%;
}
@media screen and (max-width: 940px) {
  .Vitual_slider_img {
    width: 100%;
  }
}
.Vitual_slider_2 {
  background-color: #dccdf4;
  width: 50%;
}
@media screen and (max-width: 940px) {
  .Vitual_slider_2 {
    width: 100%;
    margin: auto;
    align-items: center;
  }
}
.Vitual_slider_2_content {
  padding: 0 80px;
}
@media screen and (max-width: 1030px) {
  .Vitual_slider_2_content {
    padding-left: 10px;
    padding-right: 80px;
  }
}
@media screen and (max-width: 940px) {
  .Vitual_slider_2_content {
    padding: 0 80px;
  }
}
@media screen and (max-width: 600px) {
  .Vitual_slider_2_content {
    padding: 0;
    width: 95%;
    margin: auto;
  }
}
.Vitual_slider_2_content h1 {
  color: var(--text-color, #333);
  font-family: Georgia;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
}
@media screen and (max-width: 600px) {
  .Vitual_slider_2_content h1 {
    font-size: 28px;
  }
}
.Vitual_slider_2_content p {
  color: #787676;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  padding-bottom: 30px;
}
.Vitual_slider_2_content_box > img {
  max-width: 100%;
}
.Vitual_slider_2_content_box {
  padding-bottom: 30px;
  width: 80%;
}
@media screen and (max-width: 940px) {
  .Vitual_slider_2_content_box {
    width: 90%;
  }
}
.Vitual_slider .arrow {
  display: flex;
  justify-content: space-between;
}
.Vitual_arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 1;
  margin-top: 340px;
  z-index: 1;
  transform: translateY(-50%);
  width: 100%; /* Set the width of your arrow */
  height: 30px; /* Set the height of your arrow */
}
.Vitual_arrow button {
  width: 48px;
  height: 48px;
  border-radius: 40px;
  border: none;
  font-size: 16px;
  font-weight: 700;
}
.Vitual_arrow button:hover {
  background-color: #333;
  color: white;
}
.Vitual_slider {
  position: relative;
}

.auth_container-logo {
  width: 450px;
  margin: 50px auto 20px;
}
@media (max-width: 560px) {
  .auth_container-logo {
    width: 100%;
    margin: 30px 20px 0;
  }
}
.auth_container_form {
  box-shadow: 0px 1px 10px rgba(120, 118, 118, 0.15);
  border-radius: 10px;
  width: 450px;
  display: block;
  margin: 40px auto;
  padding: 30px 40px;
  box-sizing: border-box;
}
@media (max-width: 560px) {
  .auth_container_form {
    box-shadow: none;
    width: 100%;
    padding: 20px 20px 30px;
    margin-top: 20px;
  }
}
.auth_container_form-email {
  width: 70px;
}
.auth_container_form-text {
  margin-bottom: 20px;
}
.auth_container_form-password {
  position: relative;
}
.auth_container_form-password div {
  width: 25px;
  position: absolute;
  top: 40px;
  right: 15px;
}
.auth_container_form-password div img {
  width: 20px;
}
.auth_container_form h3 {
  font-weight: 700;
}
.auth_container_form p {
  color: #787676;
}
.auth_container_form label {
  color: #333333;
}
.auth_container_form input {
  border: 1px solid #D9DCE1;
  border-radius: 7px;
  padding: 10px 20px;
  margin: 5px 0 20px;
  width: 100%;
  color: #787676;
}
.auth_container_form input:focus {
  outline: none;
}
.auth_container_form select {
  border: 1px solid #D9DCE1;
  border-radius: 7px;
  padding: 10px 20px;
  margin: 5px 0 20px;
  width: 100%;
  color: #787676;
}
.auth_container_form select:focus {
  outline: none;
}
.auth_container_form button {
  background: #773dd3;
  border: 1px solid #773dd3;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  margin: 10px 0 20px;
  text-align: center;
  color: white;
  font-weight: 600;
}
.auth_container_form span {
  color: #773dd3;
  font-weight: 700;
  cursor: pointer;
}
.auth_verify {
  text-align: center;
  margin: 30px 0;
}
.auth_verify img {
  display: block;
  margin: auto;
}

.mode_main {
  width: 65vw;
  font-family: "Nunito Sans", sans-serif;
}
.mode_main_logo {
  margin-top: 20px;
  margin-left: 100px;
  margin-bottom: 10px;
}
@media (max-width: 700px) {
  .mode_main_logo {
    margin-left: 30px;
  }
}
@media (max-width: 769px) {
  .mode_main {
    width: 100%;
  }
}

.content {
  text-align: center;
  width: 542px;
  padding: 50px;
  margin: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(120, 118, 118, 0.05);
  margin-bottom: 50px;
}
@media (max-width: 680px) {
  .content {
    width: 80%;
    padding: 40px;
  }
}
.content h3 {
  color: #1d1e2c;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.content p {
  color: #787676;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding-bottom: 48px;
}
.content h5 {
  color: #1d1e2c;
  font-family: Nunito Sans;
  font-size: 19.716px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.content_selectFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
@media (max-width: 680px) {
  .content_selectFlex {
    flex-direction: column;
  }
}
.content_selectFlex_select:hover {
  background: #cfb9f9;
}
.content_selectFlex_select {
  display: flex;
  width: 201px;
  height: 201px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 13.144px;
  background: #fff;
  box-shadow: 0px 1.64297px 6.57189px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
@media (max-width: 680px) {
  .content_selectFlex_select {
    width: 200px;
    padding: 30px;
  }
}

.course p {
  font-size: 1.1em;
}
.course_hero {
  background-color: rgba(220, 205, 244, 0.2);
  padding: 50px 0 70px;
  position: relative;
}
@media (max-width: 37.5em) {
  .course_hero {
    padding-top: 100px;
  }
}
.course_hero-text {
  position: relative;
}
.course_hero-text .dot-class {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
.course_hero-text .dot {
  width: 13.19px;
  height: 13.19px;
  border-radius: 50%;
  background-color: #773dd3;
}
.course_hero-text .class-type {
  color: #773dd3;
  font-size: 22.36px;
  line-height: 33.54px;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  padding: 5.26px 15.79px;
  border-radius: 30px;
  border: 3.73px solid rgba(207, 185, 249, 0.25);
  background-color: rgba(207, 185, 249, 0.25);
}
.course_hero-text h1 {
  font-size: 5em;
  padding-bottom: 20px;
  font-family: Georgia;
  color: #073041;
}
@media (max-width: 37.5em) {
  .course_hero-text h1 {
    font-size: 4rem;
    width: 50%;
  }
}
.course_hero-text p {
  font-size: 1.2em;
  padding-bottom: 20px;
  color: #9da8b6;
}
.course_hero_search {
  width: 100%;
  padding: 15px 140px 15px 10px;
  font-size: 1.1em;
  border-radius: 7px;
  border: 1px solid white;
  display: block;
}
@media (min-width: 992px) {
  .course_hero_search {
    width: 90%;
  }
}
.course_hero_search-box {
  position: relative;
  box-sizing: border-box;
}
.course_hero_search-button {
  position: absolute;
  top: 7px;
  right: 10px;
  border: 1px solid #773dd3;
  background: #773dd3;
  color: white;
  border-radius: 7px;
  padding: 8px 15px;
}
.course_hero-image {
  position: absolute;
  bottom: 0;
  width: 35%;
}
@media (max-width: 75em) {
  .course_hero-image {
    display: none;
  }
}
.course__btn {
  margin: 0;
  display: flex;
}
.course_learn {
  padding: 50px 0 0;
}
.course_learn-main-list {
  display: grid;
  grid-template-columns: 50px auto;
}
@media (min-width: 768px) {
  .course_learn-main-list {
    display: grid;
    grid-template-columns: 50px 400px auto;
  }
}
.course_learn-main-list h3 {
  font-size: 1.5em;
  font-weight: 700;
}
.course_learn-main-list p {
  font-size: 1.2em;
}
@media (max-width: 768px) {
  .course_learn-main-list .box3 {
    grid-column: 1/-1;
  }
}
.course_wwd {
  margin-top: 80px;
}
.course_wwd-intro h4 {
  color: #773dd3;
  font-size: 1em;
  display: none;
}
.course_wwd-intro h2 {
  color: #073041;
  font-family: Georgia;
  font-weight: 600;
}
@media (max-width: 37.5em) {
  .course_wwd-intro h2 {
    font-size: 3rem;
  }
}
.course_wwd-intro span {
  color: #773dd3;
}
.course_wwd-intro p {
  color: #9da8b6;
}
.course_wwd-flex {
  display: flex;
  max-width: 90%;
  justify-content: center;
}
.course_wwd-flex .first-div {
  margin-right: 50px;
  position: relative;
}
@media (max-width: 56.25em) {
  .course_wwd-flex .first-div {
    display: none;
  }
}
.course_wwd-flex .first-div_1 {
  position: absolute;
  top: 530px;
  left: 60px;
}
@media (max-width: 75em) {
  .course_wwd-flex .first-div_1 {
    display: none;
  }
}
@media (min-width: 601px) {
  .course_wwd-flex-inner {
    display: flex;
  }
}
@media (max-width: 56.25em) {
  .course_wwd-flex-inner {
    max-width: 100%;
  }
}
.course_wwd-flex-inner .svg-box {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: rgba(207, 185, 249, 0.2);
}
.course_wwd-flex-inner .info {
  width: 245px;
  margin: 30px 30px 10px;
}
@media (max-width: 56.25em) {
  .course_wwd-flex-inner .info {
    margin: 40px 20px;
  }
}
@media (max-width: 37.5em) {
  .course_wwd-flex-inner .info {
    width: 100%;
    justify-content: center;
  }
}
.course_wwd-imagebox {
  width: 400px;
  height: 600px;
  margin-top: 30px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 75em) {
  .course_wwd-imagebox {
    display: none;
  }
}
.course_wwd-imagebox img {
  width: auto;
  height: 100%;
}
.course_wwd-btn {
  margin: 10px 0;
  display: flex;
}
.course_mode {
  margin-top: 80px;
  margin-bottom: 70px;
}
.course_mode-intro h4 {
  color: #773dd3;
  font-size: 1em;
}
.course_mode-intro h2 {
  color: #073041;
  font-family: Georgia;
}
.course_mode-container {
  margin-top: 40px;
}
@media (min-width: 601px) {
  .course_mode-container {
    display: flex;
  }
}
.course_mode-container p {
  color: #9da8b6;
}
.course_mode-container h3 {
  color: #073041;
  font-family: Georgia;
}
@media (max-width: 37.5em) {
  .course_mode-container h3 {
    font-size: 18px;
  }
}
.course_mode-container .svg-box {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: rgba(207, 185, 249, 0.3);
  position: relative;
}
.course_mode-container .svg-box img {
  position: absolute;
  top: 10px;
  left: 10px;
}
@media (min-width: 800px) {
  .course_mode-container .lenght1 {
    padding-right: 50px;
  }
}
.course_mode-container .boxes {
  margin: 10px 10px 30px;
}
.course_mode-container .mode-pic {
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
}
@media (max-width: 56.25em) {
  .course_mode-container .mode-pic {
    display: none;
  }
}
.course_mode-quote {
  margin: 50px auto 80px;
  text-align: center;
}
@media (min-width: 900px) {
  .course_mode-quote {
    width: 850px;
  }
}
.course_mode-quote img {
  display: block;
  margin: auto auto 15px;
  width: 50px;
}
.course_mode-quote p {
  font-size: 1.6em;
}
.course_mode-quote span {
  color: #9da8b6;
}
.course_mode-intro2 {
  text-align: center;
}
.course_mode-tutors {
  margin: 40px 0;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 37.5em) {
  .course_mode-tutors {
    overflow: auto;
  }
}
.course_mode-tutors-box {
  width: 250px;
  margin: 5px 10px;
  padding: 10px;
  box-shadow: 0px 5px 10px rgba(31, 16, 53, 0.1);
  border-radius: 10px;
  text-align: center;
}
.course_mode-tutors-box div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f5fc;
}
.course_mode-tutors-box h5 {
  font-size: 1.3em;
}
.course_mode-tutors-box img {
  width: 30px;
}
.course_mode-tutors div {
  display: inline-block;
}
.course_purple {
  background: #5726a6;
  border-radius: 25px;
  padding: 50px;
  margin: 70px 50px 100px;
  color: white;
}
@media (max-width: 75em) {
  .course_purple {
    margin: 50px 0 70px;
    padding: 50px 20px;
  }
}
.course_purple a {
  color: white;
}
.course_purple_txt {
  text-align: center;
}
.course_purple p {
  font-size: 1.2em;
}
.course_purple h1 {
  font-size: 3em;
}
.course_purple-main {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
@media (max-width: 700px) {
  .course_purple-main {
    display: block;
  }
}
.course_purple-main .grad-box {
  text-align: center;
}
@media (min-width: 700px) {
  .course_purple-main .grad-box {
    width: 300px;
  }
}
@media (min-width: 700px) {
  .course_purple-main .undergrad-box {
    width: 33%;
  }
}
.course_purple-main-boxes {
  border: 2px solid white;
  border-radius: 15px;
  margin: 15px;
  padding: 20px;
}
@media (max-width: 700px) {
  .course_purple-main-boxes {
    margin: 30px 15px;
  }
}
.course_purple-main-boxes h4 {
  font-size: 1.3em;
}
.course_purple-main-boxes span {
  font-size: 0.7em;
}
.course_purple-btn {
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 400px) {
  .course_purple-btn {
    display: block;
    margin-left: 15px;
  }
}
.course_purple-btn p {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}
@media (max-width: 400px) {
  .course_purple-btn p {
    margin-top: 20px;
    margin-left: 0px;
    text-align: center;
  }
}
.course .book-session {
  padding: 0 90px;
}
@media (max-width: 1200px) {
  .course .book-session {
    padding: 0 60px;
  }
}
@media (max-width: 600px) {
  .course .book-session {
    padding: 0 16px;
  }
}
.course .book-session-container {
  display: flex;
  justify-content: space-between;
  border: 1px solid #F8F5FD;
  border-radius: 28px;
  padding: 0px 95.66px;
  background: #F8F5FD;
}
.course .book-session-container-mobile {
  display: none;
}
@media (max-width: 600px) {
  .course .book-session-container-mobile {
    display: block;
    padding-top: 32px;
  }
}
@media (max-width: 1200px) {
  .course .book-session-container {
    padding: 0 80px;
  }
}
@media (max-width: 900px) {
  .course .book-session-container {
    padding: 0 40px;
  }
}
@media (max-width: 600px) {
  .course .book-session-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.course .book-session-container h5 {
  font-family: Georgia;
  font-size: 56px;
  line-height: 41.85px;
  color: #773dd3;
  margin-top: 71px;
  margin-bottom: 28px;
  font-weight: 700;
}
.course .book-session-container p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 28px;
  line-height: 41.85px;
  color: #787676;
  margin-bottom: 28.7px;
  font-weight: 400;
  width: 90%;
}
.course .book-session-container button {
  background: #773dd3;
  padding: 19.13px 44.24px;
  border-radius: 9.57px;
  color: #fff;
  font-size: 21.52px;
  font-family: "Nunito Sans", sans-serif;
  line-height: 21.52px;
  margin-bottom: 71.15px;
  font-weight: 700;
  border: none;
}
.course .book-session-container-text {
  position: relative;
}
.course .book-session-container-text img {
  position: absolute;
  top: 115px;
  right: 120px;
}
.course .book-session-container-pic > img {
  max-width: 100%;
}
.course .book-session-container-pic {
  max-width: 80%;
}
@media (max-width: 600px) {
  .course .book-session-container-pic {
    display: none;
  }
}
@media (max-width: 1200px) {
  .course .book-session-container h5 {
    font-size: 36px;
  }
  .course .book-session-container p {
    font-size: 20px;
    line-height: 36.85px;
  }
  .course .book-session-container-text img {
    display: none;
  }
}
@media (max-width: 900px) {
  .course .book-session-container h5 {
    font-size: 25px;
    margin-top: 51px;
  }
  .course .book-session-container p {
    font-size: 16px;
    line-height: 30.85px;
  }
  .course .book-session-container button {
    padding: 15.13px 34.24px;
    font-size: 16.52px;
  }
}
@media (max-width: 600px) {
  .course .book-session-container h5 {
    font-size: 17px;
    line-height: 26.85px;
    margin: 0px;
    text-align: center;
  }
  .course .book-session-container p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px;
  }
  .course .book-session-container button {
    padding: 15.13px 34.24px;
    font-size: 16.52px;
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
  }
}

.about__nav {
  background-color: #fff;
  margin-top: 100px;
}
@media (max-width: 56.25em) {
  .about__nav {
    margin-top: 80px;
  }
}
@media (max-width: 37.5em) {
  .about__nav {
    margin-top: 15rem;
  }
}
.about__top {
  margin-top: 5rem;
}
@media (max-width: 37.5em) {
  .about__top {
    margin-top: 7rem;
  }
}
.about__top--container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}
.about__top--up {
  display: flex;
  align-items: center;
}
@media (max-width: 37.5em) {
  .about__top--up {
    display: block;
  }
}
.about__top--up div {
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
}
.about__top--up div:first-child {
  width: 24rem;
  height: 26rem;
  background-image: url(../images/Grazac-website/IMG_8350.JPG);
  background-position: left -60px bottom 0px;
  align-self: flex-end;
  position: relative;
}
@media (max-width: 37.5em) {
  .about__top--up div:first-child {
    display: none;
  }
}
.about__top--up div:first-child img {
  position: absolute;
  left: -7rem;
  top: -4rem;
  z-index: -100;
}
.about__top--up div:nth-child(2) {
  height: 31.5rem;
  width: 33rem;
  background-image: url(../images/Grazac-website/IMG_8304.JPG);
  background-position: right -30px bottom 0px;
  margin-left: 2rem;
}
@media (max-width: 37.5em) {
  .about__top--up div:nth-child(2) {
    display: none;
  }
}
.about__top--down {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.about__top--down div {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.about__top--down div:first-child {
  width: 38rem;
  height: 29rem;
  background-image: url(../images/Grazac-website/IMG_8297.JPG);
}
.about__top--down div:last-child {
  width: 46rem;
  height: 35rem;
  margin-left: 2rem;
  background-image: url(../images/Grazac-website/IMG_8324.JPG);
}
.about__top--text {
  display: block;
  flex-basis: 35%;
  margin: 0 auto;
}
.about__top--text h1 {
  font-size: 5rem;
  color: #073041;
  font-family: Georgia;
  font-weight: bold;
}
@media (max-width: 37.5em) {
  .about__top--text h1 {
    font-size: 3.5rem;
  }
}
.about__top--text p {
  font-size: 1.8rem;
  color: #9da8b6;
  line-height: 30px;
}
@media (max-width: 37.5em) {
  .about__top--text p {
    width: 80%;
    margin-bottom: 5rem;
  }
}
.about__middle {
  padding: 8rem 2rem 10rem;
  background-color: #f1f5fc;
}
.about__middle--container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}
.about__middle--cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  margin-top: 3rem;
}
@media (max-width: 37.5em) {
  .about__middle--cards {
    display: block;
  }
}
.about__middle--card {
  background-color: #fff;
  border-radius: 5px;
  padding: 3rem 2rem;
}
@media (max-width: 37.5em) {
  .about__middle--card:not(:last-child) {
    margin-bottom: 3rem;
  }
}
.about__middle--card h3 {
  color: #073041;
  width: 80%;
  font-size: 2rem;
  font-weight: bold;
  font-family: Georgia;
  line-height: 140%;
}
@media (max-width: 37.5em) {
  .about__middle--card h3 {
    width: 60%;
    line-height: 30px;
  }
}
.about__middle--card p {
  line-height: 30px;
  font-size: 1.4rem;
  font-family: "Nunito Sans", sans-serif;
  color: #9da8b6;
}
@media (max-width: 37.5em) {
  .about__middle--card p {
    font-size: 1.6rem;
    width: 95%;
  }
}
.about__middle--card div {
  width: 10rem;
  height: 10rem;
  border-radius: 99999px;
  background-color: rgba(var(--bg-color), 0.06);
  position: relative;
}
.about__middle--card div::after {
  width: 6rem;
  height: 6rem;
  border-radius: 99999px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(var(--bg-color), 1);
  content: "";
  display: table;
}
.about__middle--head {
  width: 35rem;
}
.about__middle--head h4 {
  color: #773dd3;
  font-size: 1.4rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
}
.about__middle--head h2 {
  font-size: 3rem;
  color: #073041;
  font-family: Georgia;
  font-weight: bold;
}
.about__middle--head p {
  color: #9da8b6;
  font-size: 1.6rem;
  line-height: 30px;
}

.contact_layer {
  background: rgba(229, 229, 229, 0.2);
  margin-top: 87px;
}
@media (max-width: 37.5em) {
  .contact_layer {
    margin-top: 85px;
  }
}
.contact_layer-text {
  text-align: center;
}
.contact_layer-text h1 {
  font-size: 3.5em;
  margin-top: 70px;
  font-family: Georgia;
}
@media (max-width: 37.5em) {
  .contact_layer-text h1 {
    font-size: 3.5rem;
  }
}
.contact_layer-text p {
  font-size: 1.2em;
  color: #9da8b6;
}
.contact_layer-circles-1 {
  width: 50px;
  border-radius: 50%;
  position: absolute;
}
.contact_layer img {
  width: 50px;
  border-radius: 50%;
  position: absolute;
}
@media (max-width: 56.25em) {
  .contact_layer img {
    display: none;
  }
}
.contact_layer img:first-child {
  top: 25%;
  left: 20%;
}
.contact_layer img:nth-child(2) {
  top: 28%;
  right: 28%;
}
.contact_layer img:nth-child(3) {
  top: 70%;
  left: 10%;
}
.contact_layer img:last-child {
  top: 80%;
  right: 10%;
}
.contact_hero {
  padding: 50px 10px;
  width: 70%;
  display: block;
  margin: auto;
}
@media (max-width: 56.25em) {
  .contact_hero {
    width: 80%;
  }
}
@media (max-width: 37.5em) {
  .contact_hero {
    width: 100%;
    box-sizing: border-box;
  }
}
@media (min-width: 700px) {
  .contact_hero-flexdiv {
    display: flex;
  }
}
@media (min-width: 700px) {
  .contact_hero-flexdiv-1 {
    width: 50%;
  }
}
@media (min-width: 700px) {
  .contact_hero-flexdiv-2 {
    margin-left: 10px;
    width: 50%;
  }
}
.contact_hero label {
  color: #073041;
  font-size: 1.1em;
  margin-left: 5px;
}
.contact_hero label span {
  color: #ff570a;
}
.contact_hero input {
  background: transparent;
  margin: 10px 5px 30px;
  border-radius: 10px;
  width: 81%;
  border: 1px solid #eff2f7;
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
}
.contact_hero textarea {
  background: transparent;
  margin: 10px 5px 30px;
  border-radius: 10px;
  width: 81%;
  border: 1px solid #eff2f7;
  padding: 10px 15px;
  height: 150px;
  box-sizing: border-box;
  width: 100%;
}
.contact_hero-privacy .download-brochure {
  color: white;
  margin: 15px 0 30px;
  background: #773dd3;
  border: 2px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}
.contact_hero-privacy p {
  color: #9da8b6;
  font-size: 1.2em;
}
.contact_hero-privacy span {
  color: #773dd3;
}
.contact_hero-privacy button {
  color: white;
  margin: 15px 0 30px;
  background: #773dd3;
  border: 2px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}
.contact_hero-privacy button:last-child {
  color: #773dd3;
  background: transparent;
  margin: 15px 0 30px 15px;
}
@media (min-width: 600px) {
  .contact_hero_first {
    width: 50%;
  }
}
.contact_hero_first h1 {
  font-size: 3.5em;
  line-height: 60px;
  font-family: Georgia;
}
.contact_hero_first p {
  font-size: 1.2em;
  max-width: 400px;
}
@media (min-width: 600px) {
  .contact_hero_second {
    width: 50%;
  }
}
.contact_hero_second-btn {
  margin: 20px 0 0 10px;
}
.contact_hero_second-btn p {
  font-size: 1.2em;
  margin-top: 10px;
}
@media (min-width: 900px) {
  .contact_hero_insecond {
    display: flex;
  }
}
.contact_campus {
  margin: 100px 0;
}
.contact_campus-intro h2 {
  font-family: Georgia;
}
@media (max-width: 37.5em) {
  .contact_campus-intro h2 {
    font-size: 1.7em;
  }
}
.contact_campus-intro h4 {
  color: #773dd3;
  font-size: 0.9em;
  font-family: Georgia;
}
.contact_campus-intro p {
  color: #9da8b6;
  font-size: 1.2em;
}
.contact_campus-intro-grey-box {
  width: 400px;
  height: 250px;
  background: #c4c4c4;
}
@media (max-width: 37.5em) {
  .contact_campus-intro-grey-box {
    width: 100%;
  }
}
@media (min-width: 601px) {
  .contact_campus-main {
    display: flex;
  }
}
.contact_campus-main-div h3 {
  font-size: 1.5em;
  font-family: Georgia;
}
@media (max-width: 37.5em) {
  .contact_campus-main-div h3 {
    font-size: 1.2em;
    padding-top: 20px;
  }
}
@media (min-width: 700px) {
  .contact_campus-main-div {
    margin: 30px 10px;
  }
}
.contact_campus-main p {
  font-size: 1.1em;
  color: #9da8b6;
}
@media (max-width: 37.5em) {
  .contact_campus-main p {
    font-size: 1em;
  }
}
.contact_campus-main img {
  width: 30px;
  height: 30px;
}
@media (max-width: 37.5em) {
  .contact_campus-main img {
    width: 20px;
    height: 20px;
  }
}
.contact_campus-main .social {
  width: 30px;
  height: 30px;
  padding: 3px;
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  display: inline-block;
}
@media (max-width: 37.5em) {
  .contact_campus-main .social {
    width: 20px;
    height: 20px;
  }
}

.svg-box1 {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: rgba(102, 92, 121, 0.25);
  display: block;
  margin: auto;
  position: relative;
}
.svg-box1 img {
  position: absolute;
  top: 10px;
  left: 20%;
}

.svg-box2 {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: #cfb9f9 25%;
}

.boxes22 {
  width: 50%;
}
@media (max-width: 37.5em) {
  .boxes22 {
    width: 100%;
  }
}

.boxes33 {
  width: 33%;
}
@media (max-width: 56.25em) {
  .boxes33 {
    width: 50%;
  }
}
@media (max-width: 37.5em) {
  .boxes33 {
    width: 100%;
  }
}

.gga_hero {
  background-color: rgba(254, 244, 191, 0.2);
  position: relative;
  padding-top: 60px;
}
@media (max-width: 37.5em) {
  .gga_hero {
    padding-top: 30px;
  }
}
.gga_hero-text {
  position: relative;
  height: calc(100vh - 80px);
}
@media (max-width: 600px) {
  .gga_hero-text {
    height: 350px;
  }
}
.gga_hero-text-b {
  position: absolute;
  top: 18%;
}
@media (max-width: 37.5em) {
  .gga_hero-text-b {
    top: 15%;
  }
}
.gga_hero-text h1 {
  font-size: 5em;
  font-family: Georgia;
  padding-bottom: 20px;
  color: #073041;
}
@media (max-width: 37.5em) {
  .gga_hero-text h1 {
    font-size: 4rem;
    width: 50%;
  }
}
.gga_hero-text p {
  font-size: 1.2em;
  padding-bottom: 30px;
  color: #9da8b6;
}
.gga_hero-image {
  display: block;
  margin: 70px auto 0;
  width: 60%;
}
@media (max-width: 75em) {
  .gga_hero-image {
    display: none;
  }
}
@media (max-width: 600px) {
  .gga_hero-image {
    width: 100%;
    display: block;
  }
}
@media (min-width: 900px) {
  .gga_flex {
    display: flex;
  }
  .gga_flex-half1 {
    width: 50%;
  }
  .gga_flex-half2 {
    width: 50%;
  }
}
@media (min-width: 601px) {
  .gga_container {
    display: flex;
    flex-wrap: wrap;
  }
}
.gga_cardview {
  border: 1px solid #f1f5fc;
  border-radius: 10px;
  margin: 10px 15px 20px;
}
.gga_cardview-box {
  width: 100%;
  height: 150px;
  background: #cfb9f9;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  display: relative;
}
.gga_cardview-text {
  padding: 20px 15px;
}
.gga_cardview-text-section {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
}
.gga_cardview-text-section-1 {
  width: 150px;
  margin-bottom: 10px;
}
.gga_cardview-text-section span {
  display: inline-block;
  font-size: 1em;
}
.gga_cardview-text .circle-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: #cfb9f9;
}
.gga_cardview-text h4 {
  font-size: 1.3em;
}
.gga_cardview-text p {
  font-size: 1.1em;
}

.svg-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes44 {
  width: 50%;
  margin-right: 20px;
  border-radius: 20px;
}
@media (max-width: 37.5em) {
  .boxes44 {
    width: 100%;
  }
}

.boxes55 {
  width: 50%;
  margin-right: 20px;
  border-radius: 20px;
}
@media (max-width: 992px) {
  .boxes55 {
    width: 100%;
  }
}

.background1 {
  background: url("../images/background.png");
  background-repeat: no-repeat;
  margin: 50px 0;
}

.white-link {
  color: white;
  font-size: 1.1em;
  text-decoration: underline;
}

.white-link:hover {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.gua p {
  font-size: 1.1em;
}
.gua_hero {
  background-color: rgba(220, 205, 244, 0.2);
  padding-top: 40px;
  position: relative;
}
@media (max-width: 37.5em) {
  .gua_hero {
    padding-top: 30px;
  }
}
.gua_hero-text {
  position: relative;
  height: calc(100vh - 80px);
}
.gua_hero-text-b {
  position: absolute;
  top: 18%;
}
@media (max-width: 37.5em) {
  .gua_hero-text-b {
    top: 15%;
  }
}
.gua_hero-text h1 {
  font-size: 5em;
  padding-bottom: 20px;
  font-family: Georgia;
  color: #073041;
}
@media (max-width: 37.5em) {
  .gua_hero-text h1 {
    font-size: 4rem;
    width: 50%;
  }
}
.gua_hero-text p {
  font-size: 1.2em;
  padding-bottom: 20px;
  color: #9da8b6;
}
.gua_hero_search {
  width: 100%;
  padding: 15px 140px 15px 10px;
  font-size: 1.1em;
  border-radius: 7px;
  border: 1px solid white;
  display: block;
}
@media (min-width: 992px) {
  .gua_hero_search {
    width: 90%;
  }
}
.gua_hero_search-box {
  position: relative;
  box-sizing: border-box;
}
.gua_hero_search-button {
  position: absolute;
  top: 7px;
  right: 10px;
  border: 1px solid #773dd3;
  background: #773dd3;
  color: white;
  border-radius: 7px;
  padding: 8px 15px;
}
.gua_hero-image {
  position: absolute;
  bottom: 0;
  width: 35%;
}
@media (max-width: 75em) {
  .gua_hero-image {
    display: none;
  }
}
.gua_highlight {
  margin-top: 40px;
}
@media (min-width: 601px) {
  .gua_highlight {
    display: flex;
    height: 500px;
  }
}
.gua_highlight-box1 {
  background: url("../images/julia-cameron.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 601px) {
  .gua_highlight-box1 {
    height: 500px;
    margin-bottom: 20px;
  }
}
.gua_highlight-box2 {
  background: url("../images/pixabay007.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 601px) {
  .gua_highlight-box2 {
    height: 500px;
  }
}
.gua_highlight-box3 {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  height: 100%;
  width: 100%;
  color: white;
  position: relative;
}
.gua_highlight-box4 {
  position: absolute;
  bottom: 40px;
  left: 20px;
  right: 20px;
}
.gua_highlight-box4 h3,
.gua_highlight-box4 a {
  font-family: Georgia;
}
.gua_cardview {
  border: 1px solid #f1f5fc;
  border-radius: 10px;
  margin: 10px 15px 30px;
  background-color: white;
  height: 400px;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 1190px) {
  .gua_cardview {
    height: -moz-fit-content;
    height: fit-content;
  }
}
.gua_cardview p {
  color: #787676;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.gua_cardview span {
  font-size: 1em;
  font-family: Georgia;
  padding-top: 40px;
  color: #773dd3;
  font-weight: bold;
  position: absolute;
  bottom: 16px;
}
.gua_cardview-box {
  height: 150px;
  background: #cfb9f9;
  border-radius: 10px;
  margin: 10px 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  display: relative;
}
.gua_cardview-box img {
  width: 100%;
  height: auto;
}
.gua_cardview-text {
  padding: 20px 15px;
}
.gua_cardview-text h4 {
  font-family: Georgia;
  font-weight: 600;
}
.gua_cardview-text span {
  cursor: pointer;
}
.gua_advice {
  background: #fef4bf;
  margin-top: 70px;
  padding: 0 20px;
}
.gua_advice-box {
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .gua_advice-box {
    display: flex;
  }
}
.gua_advice-box-img {
  width: 80%;
}
@media (max-width: 992px) {
  .gua_advice-box-img {
    display: none;
  }
}
.gua_advice-box-text {
  max-width: 400px;
  margin-top: 150px;
}
@media (max-width: 992px) {
  .gua_advice-box-text {
    margin: 80px auto;
    display: block;
  }
}
.gua_advice-box h2 {
  font-size: 3em;
  font-family: Georgia;
  font-weight: 600;
  margin-bottom: 20px;
}
@media (max-width: 37.5em) {
  .gua_advice-box h2 {
    font-size: 3.5rem;
  }
}
.gua_advice-box p {
  color: #9da8b6;
  font-size: 1.2em;
}
.gua_advice-box span {
  color: #773dd3;
}
.gua_advice-box button {
  color: white;
  margin-top: 15px;
  background: #773dd3;
  border: 1px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}
.gua_advice-box .second-button {
  color: #773dd3;
  margin-top: 15px;
  margin-left: 15px;
  background: transparent;
  border: 2px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}

.coming__hero {
  height: 100vh;
}
.coming__container {
  display: flex;
  height: 100%;
}
.coming__container--left {
  flex-basis: 50%;
  position: relative;
}
@media (max-width: 56.25em) {
  .coming__container--left {
    flex-basis: 60%;
  }
}
@media (max-width: 37.5em) {
  .coming__container--left {
    flex-basis: 100%;
  }
}
.coming__container--left div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}
.coming__container--left div h3 {
  font-size: 1.6rem;
  color: #773dd3;
  font-family: "Nunito Sans", sans-serif;
}
.coming__container--left div h1 {
  color: #073041;
  font-size: 4.2rem;
  font-family: Georgia;
  font-weight: bold;
}
@media (max-width: 37.5em) {
  .coming__container--left div h1 {
    font-size: 3.5rem;
  }
}
.coming__container--left div form {
  display: block;
}
.coming__container--left div form label {
  display: block;
}
.coming__container--left div form input {
  padding: 1rem;
  border: 1px solid #eff2f7;
  border-radius: 5px;
  color: #9da8b6;
  font-size: 1.6rem;
  width: 100%;
  outline: none;
  transition: all 0.2s;
  margin-top: 1rem;
}
.coming__container--left div form input:focus {
  border-color: #773dd3;
}
.coming__container--left div form button {
  display: block;
  background-color: #773dd3;
  font-size: 1.6rem;
  font-family: Georgia;
  border: none;
  outline: none;
  color: #fff;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  font-weight: normal;
  border-radius: 3px;
}
.coming__container--right {
  flex-basis: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 56.25em) {
  .coming__container--right {
    flex-basis: 40%;
  }
}
@media (max-width: 37.5em) {
  .coming__container--right {
    display: none;
  }
}
.coming__container--right-hire {
  background-image: url(../images/hire.jpg);
}
.coming__container--right-nysc {
  background-image: url(../images/nysc.jpg);
}
.coming__container--right-careers {
  background-image: url(../images/careers.jpg);
}
.coming__container--right-training {
  background-image: url(../images/training.jpg);
}

.form {
  padding: 50px 10px;
  width: 70%;
  display: block;
  margin: auto;
}
@media (max-width: 56.25em) {
  .form {
    width: 80%;
  }
}
@media (max-width: 37.5em) {
  .form {
    width: 100%;
    box-sizing: border-box;
  }
}
.form_top {
  padding: 60px 0;
  background: url("../images/form.png");
  background-color: #773dd3;
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
}
.form_top h1 {
  font-size: 2.5em;
  font-family: Georgia;
  width: 90%;
  display: block;
  margin: 0 auto 20px;
}
.form_top p {
  font-size: 1.1em;
  font-family: "Nunito Sans", sans-serif;
  width: 70%;
  display: block;
  margin: auto;
}
@media (max-width: 56.25em) {
  .form_top p {
    width: 90%;
  }
}
@media (min-width: 700px) {
  .form-flexdiv {
    display: flex;
  }
}
@media (min-width: 700px) {
  .form-flexdiv-1 {
    width: 50%;
  }
}
@media (min-width: 700px) {
  .form-flexdiv-2 {
    margin-left: 10px;
    width: 50%;
  }
}
.form label {
  color: #073041;
  font-size: 1.1em;
  margin-left: 5px;
}
.form label span {
  color: #ff570a;
}
.form .input {
  background: transparent;
  margin: 10px 5px 30px;
  border-radius: 10px;
  width: 81%;
  border: 1px solid #eff2f7;
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
}
.form .radio-input {
  margin: 10px 10px 20px;
}
.form .show-amount {
  font-size: 1.1em;
  margin: 0 10px 20px;
}
.form textarea {
  background: transparent;
  margin: 10px 5px 30px;
  border-radius: 10px;
  width: 81%;
  border: 1px solid #eff2f7;
  padding: 10px 15px;
  height: 150px;
  box-sizing: border-box;
  width: 100%;
}
.form-privacy .download-brochure {
  color: white;
  margin: 15px 0 30px;
  background: #773dd3;
  border: 2px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}
.form-privacy p {
  color: #9da8b6;
  font-size: 1.2em;
}
.form-privacy span {
  color: #773dd3;
}
.form-privacy button {
  color: white;
  margin: 15px 0 30px;
  background: #773dd3;
  border: 2px solid #773dd3;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 10px;
}
.form-privacy button:last-child {
  color: #773dd3;
  background: transparent;
  margin: 15px 0 30px 15px;
}
@media (min-width: 600px) {
  .form_first {
    width: 50%;
  }
}
.form_first h1 {
  font-size: 3.5em;
  line-height: 60px;
  font-family: Georgia;
}
.form_first p {
  font-size: 1.2em;
  max-width: 400px;
}
@media (min-width: 600px) {
  .form_second {
    width: 50%;
  }
}
.form_second-btn {
  margin: 20px 0 0 10px;
}
.form_second-btn p {
  font-size: 1.2em;
  margin-top: 10px;
}
@media (min-width: 900px) {
  .form_insecond {
    display: flex;
  }
}

.form-info {
  background-color: #773dd3;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 2rem;
  border-radius: 5px;
}
.form-info span {
  color: #ff570a;
}

.faq_hero {
  background: rgba(220, 205, 244, 0.2);
  margin-bottom: 30px;
}
.faq_hero_content {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}
.faq_hero_content h1 {
  font-family: Georgia;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  margin: auto;
  color: #073041;
}
@media (max-width: 800px) {
  .faq_hero_content h1 {
    font-size: 30px;
  }
}
.faq_hero_content p {
  font-family: "Nunito", "Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  width: 663px;
  color: #9da8b6;
  text-align: center;
}
@media (max-width: 600px) {
  .faq_hero_content p {
    width: 100%;
  }
}

.hire h1, .hire h2, .hire h3, .hire h4 {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #313131;
}
.hire-bg {
  background: url("../images/hire-png.png"), #FDFDFD;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hire_hero {
  background: url("../images/hire-png.png"), #FDFDFD;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0;
  box-sizing: border-box;
}
.hire_hero h1 {
  font-size: 5em;
  font-weight: 500;
}
.hire_hero p {
  font-size: 1.3em;
  color: #9DA8B6;
  padding: 15px 15px 15px 0;
}
.hire_hero button {
  color: white;
  background: #773dd3;
  border: 1px solid #773dd3;
  font-size: 1.3em;
  border-radius: 5px;
  padding: 8px 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.hire_hero-carousel {
  width: 400px;
  height: 500px;
  display: block;
  overflow: hidden;
  margin: auto;
  border-radius: 0 0 60% 60%;
}
@media (max-width: 992px) {
  .hire_hero-carousel {
    display: none;
  }
}
.hire_hero-carousel-mainImage {
  -o-object-fit: cover;
     object-fit: cover;
  height: 90%;
  width: 100%;
}
.hire_hero-carousel-frame {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
}
.hire_hero-carousel-frame-image {
  width: 45px;
  height: 45px;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  overflow: hidden;
}
.hire_hero-carousel-frame-image img {
  width: 100%;
  height: auto;
}
.hire_hero-carousel .box1 {
  left: 10%;
  top: 100px;
}
.hire_hero-carousel .box2 {
  right: 10%;
  top: 170px;
}
.hire_hero-carousel .box3 {
  top: 300px;
  left: 12%;
}
.hire_hero-carousel-textbox {
  border-radius: 15px;
  background: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 3px 30px 7px;
  position: absolute;
  bottom: 30px;
  width: 300px;
  left: calc(50% - 150px);
}
.hire_hero-carousel-textbox h4 {
  font-size: 1.6em;
  margin-bottom: 0 !important;
}
.hire_hero-carousel-textbox p {
  padding: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1em;
}
.hire_hero-carousel-textbox img {
  margin-top: 0;
}
.hire_why {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.hire_why-img {
  width: 100%;
  height: 60rem;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 5rem;
}
@media (min-width: 1024px) {
  .hire_why-img {
    height: 60rem;
  }
}
@media (min-width: 1440px) {
  .hire_why-img {
    height: 40rem;
  }
}
@media (min-width: 1910px) {
  .hire_why-img {
    height: 50rem;
  }
}
@media (min-width: 2560px) {
  .hire_why-img {
    height: 50rem;
  }
}
.hire_why-content h2 {
  font-size: 3em;
  margin-top: 50px;
}
.hire_why-content-heading {
  font-size: 1.4em;
  font-weight: 700;
  margin-top: 40px;
  color: #313131;
}
.hire_why-content-paragraph {
  font-size: 1.1em;
  color: #666666;
}
.hire_talents h2 {
  font-size: 3em;
  margin-top: 100px;
  text-align: center;
  font-weight: 700;
}
.hire_talents-define {
  margin: auto;
  max-width: 600px;
  padding: 0 20px;
}
.hire_talents-define p {
  color: #9DA8B6;
  font-size: 1.1em;
  text-align: center;
}
.hire_talents-cards {
  box-sizing: border-box;
  margin-top: 50px;
}
.hire_talents-cards-box {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-top: 40px;
  border: 2px solid transparent;
}
.hire_talents-cards-box-image {
  position: relative;
  overflow: hidden;
  padding-top: 70%;
  margin: 15px;
  border-radius: 10px;
}
.hire_talents-cards-box-image img {
  position: absolute;
  top: 0;
  width: 100%;
}
.hire_talents-cards-box-text {
  text-align: center;
}
.hire_talents-cards-box-text h4 {
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0 !important;
}
.hire_talents-cards-box-text p {
  font-size: 1.1em;
}
.hire_talents-cards-box-text-1 {
  color: #9DA8B6;
  margin: 5px 0 30px;
}
.hire_talents-cards-box-text-available {
  text-transform: uppercase;
  color: #CC9600;
  background: #FFFADE;
  width: 150px;
  padding: 10px 25px 1px;
  font-weight: 600;
  margin: 0 auto 20px;
  border-radius: 30px;
}
.hire_talents-cards-box-text-hired {
  text-transform: uppercase;
  color: #188F32;
  width: 150px;
  background: #DEFFE5;
  padding: 10px 25px 1px;
  font-weight: 600;
  margin: 0 auto 20px;
  border-radius: 30px;
}
.hire_talents-cards button {
  color: white;
  background: #773dd3;
  border: 1px solid #773dd3;
  padding: 10px 30px;
  margin: 40px auto 200px;
  font-size: 1.2em;
  border-radius: 5px;
  display: block;
}
.hire_talents-cards button img {
  margin-left: 15px;
}
.hire_listing {
  border-radius: 10px;
  display: flex;
  padding: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin: 40px;
  background-color: #fff;
}
@media (max-width: 769px) {
  .hire_listing {
    display: block;
    margin: 15px;
    padding: 15px;
  }
}
.hire_listing:hover {
  background-color: #773DD3;
  transition: background-color 800ms linear;
}
.hire_listing:hover p {
  color: white;
  transition: color 800ms linear;
}
.hire_listing:hover span:nth-child(2) {
  background-color: white !important;
  transition: background-color 800ms linear;
  color: #773dd3;
}
.hire_listing p {
  font-size: 1.2em;
}
@media (max-width: 769px) {
  .hire_listing p {
    margin-top: 10px;
  }
}
.hire_listing p span {
  margin-left: 40px;
}
@media (max-width: 769px) {
  .hire_listing p span {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
  }
}
.hire_listing-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 560px) {
  .hire_listing-image {
    margin: auto;
    display: block;
  }
}
.hire_listing-image img {
  width: 100%;
  height: auto;
}
.hire_listing-text {
  width: 100%;
  padding: 0 0 0 20px;
}
@media (max-width: 560px) {
  .hire_listing-text {
    text-align: center;
    padding: 0px;
  }
}
@media (min-width: 769px) {
  .hire_listing-text .d-flx {
    display: flex;
  }
}
.hire_listing-text-1 {
  font-size: 1.7em !important;
  font-weight: 700;
  margin: 0 !important;
}
@media (max-width: 769px) {
  .hire_listing-text-1 {
    margin: 10px 0 0px !important;
  }
}
.hire_listing-text-2-available {
  text-transform: uppercase;
  color: #CC9600;
  background: #FFFADE;
  padding: 7px 25px 5px;
  font-weight: 600;
  height: 23px;
  font-size: 1em !important;
  margin-left: auto;
  border-radius: 30px;
}
.hire_listing-text-2-hired {
  text-transform: uppercase;
  font-size: 1em !important;
  height: 23px;
  background: #DEFFE5;
  color: #188F32;
  padding: 7px 25px 5px;
  font-weight: 600;
  margin-left: auto;
  border-radius: 30px;
}
@media (max-width: 769px) {
  .hire_listing-text-3 {
    margin: 20px 0 0px !important;
  }
}
.hire_listing-pagination {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 30px 0 70px;
}
@media (max-width: 769px) {
  .hire_listing-pagination {
    margin: 10px 0 30px;
  }
}
.hire_listing-pagination-number {
  width: 40px;
  padding-top: 10px;
  margin: 0 3px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.hire_listing-pagination-text {
  padding: 10px 15px 0;
  margin: 0 3px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.hire_listing-pagination .activePage {
  background-color: #773DD3;
  color: white;
}
.hire_profile-main {
  padding: 20px;
  margin-top: 40px;
  position: relative;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 10px;
  border-radius: 20px;
  background: white;
}
.hire_profile-main .purpleBack {
  cursor: pointer;
}
@media (max-width: 400px) {
  .hire_profile-main .purpleBack {
    position: absolute;
    left: -20px;
    top: -10px;
  }
}
.hire_profile-main-bio {
  display: flex;
}
@media (max-width: 400px) {
  .hire_profile-main-bio {
    display: block;
  }
}
.hire_profile-main-bio-text {
  width: 60%;
  margin-top: 50px;
}
@media (max-width: 400px) {
  .hire_profile-main-bio-text {
    width: 100%;
    margin-top: 20px;
  }
}
.hire_profile-main-bio-text span {
  color: black !important;
  font-size: 15px;
  font-weight: 600;
  margin-left: 20px;
}
.hire_profile-main-bio-text .available {
  text-transform: uppercase;
  color: #CC9600;
  background: #FFFADE;
  font-weight: 600;
  margin: 10px 0 20px;
  font-size: 15px !important;
  border-radius: 30px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  width: 85px;
}
.hire_profile-main-bio-text .hired {
  text-transform: uppercase;
  background: #DEFFE5;
  color: #188F32;
  font-weight: 600;
  margin: 10px 0 20px;
  font-size: 15px !important;
  border-radius: 30px;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  width: 6rem;
}
.hire_profile-main-bio-image1 {
  width: 40%;
}
@media (max-width: 769px) {
  .hire_profile-main-bio-image1 {
    width: 100%;
  }
}
.hire_profile-main-bio-image {
  width: 250px;
  display: block;
  margin: auto;
  height: 250px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 560px) {
  .hire_profile-main-bio-image {
    width: 150px;
    height: 150px;
  }
}
.hire_profile-main-bio-image img {
  width: 100%;
  height: auto;
}
.hire_profile-main-bio-icons {
  width: 200px;
  display: block;
  margin: 15px auto 0;
}
.hire_profile-main-bio-icons img {
  width: 30px;
  margin: 0 10px 0;
}
@media (min-width: 992px) {
  .hire_profile-main-info {
    margin-right: 50px;
  }
}
@media (min-width: 1200px) {
  .hire_profile-main-info {
    margin-right: 100px;
  }
}
.hire_profile-main-info h4, .hire_profile-main-info p {
  color: #777777;
}
.hire_profile-main-info h4 {
  font-size: 22px;
  margin: 80px 0 0 80px;
}
@media (max-width: 769px) {
  .hire_profile-main-info h4 {
    margin: 40px 0 0 15px;
    font-size: 18px;
  }
}
.hire_profile-main-info p {
  margin-bottom: 5px;
  font-size: 18px;
}
@media (max-width: 769px) {
  .hire_profile-main-info p {
    font-size: 16px;
  }
}
.hire_profile-main-info .text-1 {
  font-size: 19px;
  font-weight: 600;
  color: #313131 !important;
}
@media (max-width: 769px) {
  .hire_profile-main-info .text-1 {
    font-size: 17px;
  }
}
.hire_profile-main-info-shift {
  margin-left: 80px;
}
@media (max-width: 769px) {
  .hire_profile-main-info-shift {
    margin-left: 15px;
  }
}
.hire_profile-main-info-shift-flx {
  display: flex;
}
@media (max-width: 560px) {
  .hire_profile-main-info-shift-flx {
    display: block;
  }
}
.hire_profile-main-info-shift-flx-1 {
  width: 40%;
}
@media (max-width: 560px) {
  .hire_profile-main-info-shift-flx-1 {
    width: 100%;
  }
}
.hire_profile-main-info-shift-flx-2 {
  width: 60%;
}
@media (max-width: 560px) {
  .hire_profile-main-info-shift-flx-2 {
    width: 100%;
  }
}
.hire_profile-backBtn {
  color: white;
  background: #773dd3;
  border: 1px solid #773dd3;
  padding: 10px 30px;
  margin: 40px auto 50px;
  font-size: 1.2em;
  border-radius: 5px;
  display: block;
}
@media (max-width: 560px) {
  .hire_profile-backBtn {
    font-size: 1em;
    padding: 6px 15px;
  }
}
.hire_profile-backBtn img {
  margin: -5px 15px 0 0;
  transform: rotate(180deg);
}
.hire_profile-backBtn2 {
  color: white;
  background: #773dd3;
  border: 1px solid #773dd3;
  padding: 10px 30px;
  margin: 40px 0 50px 15px;
  font-size: 1.2em;
  border-radius: 5px;
  display: block;
}
@media (max-width: 560px) {
  .hire_profile-backBtn2 {
    font-size: 1em;
    padding: 6px 15px;
  }
}
.hire_profile-backBtn2 img {
  margin: -5px 0 0 15px;
}
.hire_contact {
  background: #773dd3;
  border-radius: 15px;
  margin: 50px 0;
  box-sizing: border-box;
  padding: 50px 0;
}
.hire_contact-text {
  color: white;
}
@media (min-width: 769px) {
  .hire_contact-text {
    margin: 0 30px;
  }
}
@media (max-width: 560px) {
  .hire_contact-text {
    padding: 0 15px;
  }
}
.hire_contact-text h2 {
  color: white;
  font-weight: 600;
  font-size: 2.5em;
}
@media (max-width: 769px) {
  .hire_contact-text h2 {
    font-size: 2em;
  }
}
.hire_contact-text-1 {
  font-size: 1.2em;
  margin-bottom: 50px;
  max-width: 400px;
}
@media (min-width: 992px) {
  .hire_contact-text-1 {
    max-width: 350px;
  }
}
.hire_contact-text-box {
  border-radius: 10px;
  padding: 5px 20px;
  max-width: 280px;
}
@media (max-width: 560px) {
  .hire_contact-text-box {
    padding: 5px 0;
  }
}
.hire_contact-text-box:hover {
  background-color: rgba(207, 185, 249, 0.4);
}
.hire_contact-text-box-flx {
  display: flex;
}
.hire_contact-text-box-flx img {
  width: 15px;
  height: 35px;
  margin-right: 10px;
}
.hire_contact-text-box-flx p {
  margin-top: 7px;
}
.hire_contact-form {
  background: white;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 35px 20px;
  margin-right: 50px;
}
@media (max-width: 992px) {
  .hire_contact-form {
    margin: 0 10px;
  }
}
.hire_contact-form input, .hire_contact-form textarea {
  border-radius: 5px;
  width: 100%;
  border: 1px solid rgba(157, 168, 182, 0.4);
  padding: 10px 15px;
  margin-bottom: 25px;
}
.hire_contact-form button {
  width: 100%;
  color: white;
  border: 1px solid #773dd3;
  background: #773dd3;
  padding: 10px 0;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
}
.hire_dev-grey {
  color: #787676;
  font-size: 14px;
}

.join_header {
  margin-bottom: 70px;
  background-image: url("../images/svg/objects.svg");
}
.join_heading {
  text-align: center;
  font-weight: bold;
}
.join_bg1 {
  background: #F1F5FC;
  margin: 50px 0;
  padding: 50px 0;
}
.join_container {
  margin: 50px 0 20px;
}
@media (min-width: 601px) {
  .join_container {
    display: flex;
    flex-wrap: wrap;
  }
}
.join_needed {
  width: 70%;
  margin: 20px auto;
  color: #787676;
  font-size: 16px;
}
@media (max-width: 56.25em) {
  .join_needed {
    width: 90%;
  }
}
.join_container {
  margin-bottom: 80px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.join_box1 {
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 10px 24px 10px 24px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.05);
  width: 625.5px;
  height: 210px;
  margin: 10px 10px;
}
@media (max-width: 601px) {
  .join_box1 {
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 56.25em) {
  .join_box1 {
    margin: 20px;
  }
}
.join_box1 h4 {
  font-family: Georgia;
  font-weight: 600;
  color: #073041;
}
.join_box1 p {
  color: #787676;
}
@media (min-width: 700px) {
  .join_gallery-top {
    display: flex;
  }
  .join_gallery-top-left {
    width: 60%;
    margin: 20px 15px;
    overflow: hidden;
    height: 25vw;
    border-radius: 20px;
  }
  .join_gallery-top-left img {
    height: auto;
    width: 100%;
  }
  .join_gallery-top-right {
    width: 40%;
    margin: 20px 15px;
    overflow: hidden;
    height: 25vw;
    border-radius: 20px;
  }
  .join_gallery-top-right img {
    height: 100%;
    width: auto;
  }
  .join_gallery-bottom {
    display: flex;
  }
  .join_gallery-bottom-left {
    width: 45%;
    margin: 20px 15px;
    overflow: hidden;
    height: 25vw;
    border-radius: 20px;
  }
  .join_gallery-bottom-left img {
    height: auto;
    width: 100%;
  }
  .join_gallery-bottom-right {
    width: 55%;
    margin: 20px 15px;
    overflow: hidden;
    height: 25vw;
    border-radius: 20px;
  }
  .join_gallery-bottom-right img {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 700px) {
  .join_gallery img {
    width: 90%;
    display: block;
    margin: 20px auto;
    border-radius: 15px;
  }
}

.dashboard-nav {
  height: 70px;
  border-bottom: 3px solid #F8F8F8;
  display: flex;
  padding: 0 40px;
}
@media (max-width: 769px) {
  .dashboard-nav {
    padding: 0 20px;
  }
}
.dashboard-nav-menu {
  width: 35px;
  margin-right: 20px;
}
@media (min-width: 769px) {
  .dashboard-nav-menu {
    display: none;
  }
}
.dashboard-nav-status {
  display: flex;
}
@media (max-width: 769px) {
  .dashboard-nav-status h3 {
    font-size: 17px;
    font-weight: 400;
    margin-top: 25px;
  }
}
.dashboard-nav-status-dot {
  margin: 28px 0 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
@media (max-width: 769px) {
  .dashboard-nav-status-dot {
    margin: 30px 0 0 5px;
    width: 7px;
    height: 7px;
  }
}
.dashboard-nav-status .not-submitted {
  border: 1px solid #333333;
  background: #333333;
}
.dashboard-nav-status .under-review {
  border: 1px solid #E7A300;
  background: #E7A300;
}
.dashboard-nav-status .pending {
  border: 1px solid #E7A300;
  background: #E7A300;
}
.dashboard-nav-status .Selected {
  border: 1px solid #773dd3;
  background: #773dd3;
}
.dashboard-nav-status .Admitted {
  border: 1px solid #29EB7F;
  background: #29EB7F;
}
.dashboard-nav-status .Rejected {
  border: 1px solid #CC2E2E;
  background: #CC2E2E;
}
.dashboard-nav-user {
  margin-left: auto;
  display: flex;
}
.dashboard-nav-user div {
  width: 50px;
  height: 50px;
  border: 1px solid #333333;
  color: white;
  background: #333333;
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
}
@media (max-width: 769px) {
  .dashboard-nav-user div {
    width: 40px;
    height: 40px;
    margin-top: 15px;
  }
}
.dashboard-nav-user div h2 {
  margin-top: 7px;
}
@media (max-width: 769px) {
  .dashboard-nav-user div h2 {
    font-size: 23px;
  }
}
.dashboard-nav-user p {
  font-size: 25px;
  margin: 20px 0 0 20px;
}
@media (max-width: 769px) {
  .dashboard-nav-user p {
    display: none;
  }
}
.dashboard-content {
  padding: 20px 50px 40px !important;
}
@media (max-width: 769px) {
  .dashboard-content {
    padding: 20px 20px 40px !important;
  }
}
.dashboard-content-guarantor_paragraph_text {
  margin-bottom: 3rem;
}
.dashboard-content .text1 {
  color: #333333 !important;
  font-size: 18px;
  font-weight: 600;
}
.dashboard-content .assessment-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.dashboard-content .prompt-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.dashboard-content .prompt-content {
  width: 588px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 28px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  z-index: 19999;
  border: 1px solid #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dashboard-content .prompt-content h2 {
  margin: 0;
  font-size: 24px;
  line-height: 32.74px;
  color: #333;
}
.dashboard-content .prompt-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #787676;
}
.dashboard-content .prompt-content span {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .dashboard-content .prompt-content {
    width: 80%;
    padding: 16px;
  }
}
.dashboard-content .prompt-content button {
  width: 100%;
  box-sizing: border-box;
}
.dashboard-content h3, .dashboard-content h4 {
  color: #333333;
  margin: 40px 0 20px;
}
.dashboard-content p, .dashboard-content li {
  font-size: 17px;
  margin-top: 1rem;
  color: #787676;
}
.dashboard-content ul {
  margin-left: 20px;
}
.dashboard-content .checkbox {
  margin-left: 20px;
}
.dashboard-content .checkbox a {
  color: #773dd3;
  text-decoration: underline;
}
.dashboard-content .disable-btn {
  color: #E0E0E0;
  background: #FAFAFA !important;
  border: 1px solid #FAFAFA;
}
.dashboard-content button {
  color: white;
  padding: 7px 25px;
  background: #773dd3;
  border: 1px solid #773dd3;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  margin: 20px 13px 20px 0px;
}
@media (max-width: 600px) {
  .dashboard-content button {
    width: 100%;
    box-sizing: border-box;
    margin: 5px 0px;
  }
}
.dashboard-content-back {
  display: flex;
  cursor: pointer;
  margin-left: 20px;
}
.dashboard-content-back img {
  width: 15px;
}
.dashboard-content-back p {
  margin: 5px 0 0 10px;
}
.dashboard-content-back2 {
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  margin-left: 0px;
}
.dashboard-content-back2 img {
  width: 15px;
}
.dashboard-content-back2 p {
  margin: 5px 0 0 10px;
}
.dashboard-content .guarantor-form-label {
  padding: 8px 5px;
  border-radius: 28px;
  background-color: #773dd3;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  width: 176px;
  text-align: center;
}
.dashboard-content-form {
  box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
  border-radius: 15px;
  padding: 40px 40px 100px;
  margin: 0 20px 50px;
  max-width: 700px;
}
@media (max-width: 769px) {
  .dashboard-content-form {
    padding: 40px 20px 100px;
    margin: 0 0px 50px;
  }
}
.dashboard-content-form-container {
  position: relative;
  box-sizing: border-box;
}
.dashboard-content-form-container label {
  font-size: 16px;
  color: #333333;
  margin-top: 25px;
}
.dashboard-content-form-container input, .dashboard-content-form-container select, .dashboard-content-form-container textarea {
  width: 100%;
  margin: 5px 0 0;
  padding: 7px 20px;
  font-size: 16px;
  color: #787676;
  border-radius: 10px;
  border: 1px solid #D9DCE1;
}
.dashboard-content-form-container textarea {
  height: 200px;
}
.dashboard-content-form-container input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}
.dashboard-content-form-container .upload-text {
  font-size: 16px;
  color: #787676;
  position: absolute;
  background: white;
  bottom: -2px;
  left: 20px;
  min-width: 200px;
}
.dashboard-content-form-container .upload-img {
  width: 20px;
  position: absolute;
  bottom: 9px;
  right: 20px;
}
.dashboard-content-form-container-prev {
  color: #787676 !important;
  background: white !important;
  border: 1px solid #787676 !important;
  margin: 20px 15px 20px 0 !important;
  float: right;
}
.dashboard-content-form-container-image {
  width: 300px;
  margin-top: 10px;
}
@media (max-width: 400px) {
  .dashboard-content-form-container-image {
    width: 100%;
  }
}
.dashboard-content-form2 {
  box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
  border-radius: 15px;
  padding: 40px 40px 100px;
  margin: 0 20px 50px;
  margin-left: 0px;
  max-width: 700px;
}
@media (max-width: 769px) {
  .dashboard-content-form2 {
    padding: 40px 20px 100px;
    margin: 0 0px 50px;
  }
}
.dashboard-content-form2-container {
  position: relative;
  box-sizing: border-box;
}
.dashboard-content-form2-container label {
  font-size: 16px;
  color: #333333;
  margin-top: 25px;
}
.dashboard-content-form2-container input, .dashboard-content-form2-container select, .dashboard-content-form2-container textarea {
  width: 100%;
  margin: 5px 0 0;
  padding: 7px 20px;
  font-size: 16px;
  color: #787676;
  border-radius: 10px;
  border: 1px solid #D9DCE1;
}
.dashboard-content-form2-container textarea {
  height: 200px;
}
.dashboard-content-form2-container input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}
.dashboard-content-form2-container .upload-text {
  font-size: 16px;
  color: #787676;
  position: absolute;
  background: white;
  bottom: -2px;
  left: 20px;
  min-width: 200px;
}
.dashboard-content-form2-container .upload-img {
  width: 20px;
  position: absolute;
  bottom: 9px;
  right: 20px;
}
.dashboard-content-form2-container-prev {
  color: #787676 !important;
  background: white !important;
  border: 1px solid #787676 !important;
  margin: 20px 15px 20px 0 !important;
  float: right;
}
.dashboard-content-form2-container-image {
  width: 300px;
  margin-top: 10px;
}
@media (max-width: 400px) {
  .dashboard-content-form2-container-image {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .dashboard-content .det-btn-container {
    overflow-x: scroll;
  }
}
.dashboard-content-detail {
  display: flex;
  padding: 10px 20px;
}
@media (max-width: 600px) {
  .dashboard-content-detail {
    padding: 10px 0px;
    width: 600px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.dashboard-content-detail-p {
  padding: 7px 20px;
  margin-right: 10px !important;
  border-radius: 20px !important;
  background: transparent !important;
  color: #787676 !important;
  font-weight: 500;
  border: 1px solid transparent !important;
  cursor: pointer;
}
.dashboard-content-detail .active {
  color: white !important;
  background: #773dd3 !important;
}
.dashboard-content-modal-backdrop {
  background: black;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  opacity: 0.5;
}
.dashboard-content-modal-container {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  z-index: 1001;
}
.dashboard-content-modal-main {
  width: 600px;
  background: white;
  border-radius: 10px;
  position: fixed;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px;
  box-sizing: border-box;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 769px) {
  .dashboard-content-modal-main {
    top: 0;
  }
}
@media (max-width: 769px) {
  .dashboard-content-modal-main {
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin: 0;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.dashboard-content-modal-main-text {
  display: flex;
}
.dashboard-content-modal-main-text img {
  margin-left: auto;
}
.dashboard-content-modal-main-text h4 {
  font-size: 21px;
}
.dashboard-content-modal-main-frame1 {
  border: 1px solid #E5E9F2;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  display: flex;
  cursor: pointer;
}
.dashboard-content-modal-main-frame1 div {
  border: 1px solid #E5E9F2;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  margin: 5px 15px 0 0;
}
.dashboard-content-modal-main-frame1 p {
  margin-top: 10px;
  color: #52575C !important;
}
.dashboard-content-modal-main .active {
  border: 1px solid #773dd3;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  display: flex;
  cursor: pointer;
}
.dashboard-content-modal-main .active div {
  border: 1px solid #773dd3;
  border-radius: 50px;
  background: #773dd3;
  width: 30px;
  height: 30px;
  margin: 5px 15px 0 0;
}
.dashboard-content-modal-main .active p {
  margin-top: 10px;
  color: #52575C !important;
}
.dashboard-content-modal-main button {
  width: 100%;
  font-size: 17px;
  padding: 10px;
}
.dashboard-content-modal-back {
  padding-top: 0;
}

.pricing {
  align-items: center;
  margin: auto;
  width: 80%;
}
@media screen and (max-width: 1352px) {
  .pricing {
    width: 90%;
  }
}
.pricing h1 {
  color: #073041;
  text-align: center;
  font-family: Georgia;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 400px) {
  .pricing h1 {
    font-size: 50px;
  }
}
.pricing p {
  color: #787676;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.pricing .virtualButtons {
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  width: 187.5px;
  height: 60px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  background-color: #773dd3;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
@media screen and (max-width: 780px) {
  .pricing .virtualButtons {
    width: 160px;
    padding: 0px 10px;
  }
}
@media screen and (max-width: 400px) {
  .pricing .virtualButtons {
    font-size: 16px;
  }
}
.pricing .onsiteButtons {
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  width: 187.5px;
  height: 60px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #773dd3;
  color: #fff;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
@media screen and (max-width: 780px) {
  .pricing .onsiteButtons {
    width: 160px;
    padding: 0px 10px;
  }
}
@media screen and (max-width: 400px) {
  .pricing .onsiteButtons {
    font-size: 16px;
    width: 140px;
  }
}
.pricing .activebtn {
  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 28px;
  color: var(--Main-Color, #773dd3);
}
@media screen and (max-width: 400px) {
  .pricing .activebtn {
    font-size: 14px;
  }
}
.pricing_toggle {
  width: 391px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 36px;
  border: 2px solid var(--Main-Color, #773dd3);
  background: #f8fafd;
  margin: 48px auto;
  padding: 8px;
}
@media screen and (max-width: 780px) {
  .pricing_toggle {
    width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .pricing_toggle {
    width: 95%;
    gap: 8px;
  }
}
.pricing .container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
}/*# sourceMappingURL=main.css.map */