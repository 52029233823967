.modal2 {
  &__bg {
    height: 100vh;
    width: 100%;
    background: rgba(48, 50, 75, 0.1);
    mix-blend-mode: normal;
    backdrop-filter: blur(14px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000000;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    width: 50%;
    padding: 3rem 2rem;
    @include respond(phone) {
      width: 90%;
    }
  }

  &__header {
    font-family: $font-primary;
    font-size: 3.5rem;
    font-weight: normal;
    color: $color-excellent-purple;
    margin-bottom: 2rem;
  }

  &__table {
    width: 100%;

    tr {
      margin-bottom: 2rem;
    }

    th {
      color: #1f1035;
      font-size: 1.4rem;
      font-family: $font-primary;
      font-weight: normal;
    }

    td {
      color: #1f1035;
      font-size: 1.8rem;
      font-weight: bold;
      font-family: $font-secondary;
    }

    td,
    th {
      padding: 1.8rem;
    }
  }

  &__footer {
    color: $color-grey;
    font-size: 1.6rem;
    font-family: $font-secondary;
    margin-top: 2rem;

    a {
      color: #773dd3;
    }
  }
}

.infomodal {
  background-color: #fef4bf;
  position:absolute;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 10000;

  @include respond(phone) {
    //display: none;
  }

  &_content {
    display: flex;
    justify-content: center;
    margin: 20px 20px;
    @include respond(phone) {
      margin: 10px 15px;
    }

    img {
      margin: 5px 10px;
    }
    p {
      margin: 5px 10px 0 20px;
      font-size: 18px;
      line-height: 32.74px;
      color: #073041;
      @media (max-width: 768px) {
        margin: 5px 10px 0 10px;
        font-size: 12px;
        line-height: 25.74px;
        text-align: center;
      }
      @media (max-width: 450px) {
        margin: 5px 10px 0 10px;
      }
    }
    &-link{
      font-size: 16px;
      margin-left: 5px;
      color:$color-inspiring-purple;
      @media (min-width:600px) {
        display: none;
      }
    }
    button {
      background: #773dd3;
      color: white;
      margin: 0 40px 0 0;
      padding: 4px 10px;
      border: 1px solid #773dd3;
      border-radius: 7px;
      cursor: pointer;
      @media (max-width: 450px) {
        margin: 0 10px 0 0;
      }
      @media (max-width: 426px) {
        margin: 10px 10px 0 0;
      }
      @include respond(phone) {
        display: none;
      }
    
    }
  }

  &_badge{
    @include respond(phone) {
      display: none;
    }
  }
}
.web3modal {
  background-color: #fef4bf;
  // position:absolute;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 10000;

  @include respond(phone) {
    //display: none;
  }

  &_content {
    display: flex;
    justify-content: center;
    // margin: 20px 20px;
    padding: 20px 20px;
    @include respond(phone) {
      // margin: 10px 15px;
    }

    img {
      margin: 5px 10px;
    }
    p {
      margin: 5px 10px 0 20px;
      font-size: 18px;
      line-height: 32.74px;
      color: #073041;
      @media (max-width: 768px) {
        margin: 5px 10px 0 10px;
        font-size: 12px;
        line-height: 25.74px;
        text-align: center;
      }
      @media (max-width: 450px) {
        margin: 5px 10px 0 10px;
      }
    }
    &-link{
      font-size: 16px;
      margin-left: 5px;
      color:$color-inspiring-purple;
      @media (min-width:600px) {
        display: none;
      }
    }
    button {
      background: #773dd3;
      color: white;
      margin: 0 40px 0 0;
      padding: 4px 10px;
      border: 1px solid #773dd3;
      border-radius: 7px;
      cursor: pointer;
      @media (max-width: 450px) {
        margin: 0 10px 0 0;
      }
      @media (max-width: 426px) {
        margin: 10px 10px 0 0;
      }
      @include respond(phone) {
        display: none;
      }
    
    }
  }

  &_badge{
    @include respond(phone) {
      display: none;
    }
  }
}

.modal3 {
  &__bg {
    height: 100vh;
    width: 100%;
    background: rgba(48, 50, 75, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000000;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    width: 30%;
    height: 25rem;
    padding: 2rem;
    border-radius: 1rem;
    @include respond(phone) {
      width: 85%;
    }
    &--head {
      display: flex;
      justify-content: space-between;
    }
    &--svg {
      height: 2.5rem;
      width: 2.5rem;
      cursor: pointer;
      path {
        fill: $color-excellent-purple;
      }
    }
    &--body {
      font-size: 2rem;
      margin-top: 2rem;
      font-weight: 600;
      color: #1f1035;
      h1 {
        margin-bottom: 2rem;
        font-size: 4.5rem;
        color: $color-base-purple;
        font-family: $font-primary;
      }
    }
  }
}
