.nav {
  background-color: transparent;
  position: relative;
  padding: 1.5rem;
  transition: all 0.3s;
  z-index: 9999;

  &--fixed {
    background-color: $color-white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    -webkit-box-shadow: 0 4px 14px -6px rgba(7, 48, 65, 0.1);
    -moz-box-shadow: 0 4px 14px -6px rgba(7, 48, 65, 0.1);
    box-shadow: 0 4px 14px -6px rgba(7, 48, 65, 0.1);
    animation: fadeInDown 0.95s ease-in-out 0s 1 normal none running;
  }

  @include respond(phone) {
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    padding: 0 3rem;
    padding-left: 0;
    @include respond(phone) {
      padding: 2rem 3rem;
      padding-right: 0rem;
    }

    &__logo {
      @include respond(phone) {
        img {
          height: 10px !important;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    background-color: $color-white;
    padding: 1rem 2rem;
    border-radius: 5px;
    top: 3rem;
    top: 2.2rem;
    left: -2.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;

    &--active {
      opacity: 1;
      visibility: visible;
    }

    &--list {
      list-style-type: none;
      li {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__list {
    list-style-type: none;
    display: flex;
    align-items: center;

    &--mb {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      content: "";
      width: 100vw;
      height: 100vh;
      background: #773dd3;
      transition: all 500ms ease-in-out;
      padding: 5rem 2rem;
      padding-top: 12rem;

      // that's all the pen about
      clip-path: circle(30px at calc(100% - 65px) 65px);
      // for AT
      visibility: hidden;
    }

    @include respond(phone) {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    color: #9da8b6;

    @include respond(phone) {
      display: flex;
    }

    span {
      font-size: 1.6rem;
      font-weight: 600;
      display: inline-block;
      margin-right: 2rem;
      margin-top: 5px;
    }

    &:hover {
      text-decoration: underline;
      color: #9da8b6;
    }

    @include respond(phone) {
      font-size: 3rem;
      margin-bottom: 5rem;
      font-weight: bold;
      color: $color-white;
      font-family: $font-secondary;
      font-size: 4rem;
    }
  }

  &__item {
    font-size: 1.5rem;
    font-weight: normal;
    color: $color-grey;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 4rem;
      @include respond(tab-port) {
        margin-right: 2rem;
      }

      @include respond(phone) {
        margin-right: 0;
      }
    }

    .mask {
      margin-left: 1rem;
      transform: translateY(2px);
      cursor: pointer;
      @include respond(phone) {
        display: none;
      }
    }
  }

  &__btn {
    font-size: 1.6rem;
    font-family: $font-primary;
    font-weight: 500;
    letter-spacing: 0.04em;

    // margin-right: 2rem;
    transition: all 0.2s;
    @include respond(phone) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }

    &-flex{
      display: flex;
    }

    &--text {
      background-color: $color-inspiring-purple;
      padding: 1.5rem 2rem;
      color: $color-white;
      border-radius: 5px;
      font-family: $font-secondary;

      &:hover{
        color: $color-white;
      }

      &--yellow {
        background-color: $color-creative-yellow;
      }
    }

    &--text1 {
      background-color: transparent;
      padding: 1.5rem 2rem;
      color: $color-inspiring-purple;
      border-radius: 5px;
      border:2px solid $color-inspiring-purple;
      font-family: $font-secondary;
      
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    position: relative;

    .nav-tgl {
      cursor: pointer;
      margin-right: 2rem;
      width: 50px;
      height: 50px;
      background: transparent;
      border: none;
      text-align: center;
      z-index: 9009090909;
      display: none;

      @include respond(phone) {
        display: inline-block;
      }

      // making the dividers
      & > span {
        display: inline-block;
        position: relative;
        height: 2px;
        width: 20px;
        border-radius: 1px;
        background: #293335;
        vertical-align: middle;

        // the first & the third dividers
        &:before,
        &:after {
          display: inline-block;
          position: absolute;
          content: "";
          height: 2px;
          border-radius: 1px;
          background: #293335;
          // for the hover state
          transition: all 200ms;
        }
        &:before {
          top: -8px;
          left: 3px;
          width: 18px;
        }
        &:after {
          top: 8px;
          left: 6px;
          width: 16px;
        }
      }

      // ofcorse we should find a replacement for the focus state but it's not our topic
      &:focus {
        outline: none;
        background-color: white;
        border-radius: 99999px;
      }

      &:hover > span:after,
      &:hover > span:before {
        width: 20px;
        left: 0;
      }
    }

    // for the nav background (styling the nav itself is not our topic)

    // when it gits activated
    &--active {
      .nav__list--mb {
        visibility: visible;

        // that's all the pen about
        clip-path: circle(100%);
      }

      .nav-tgl > span {
        height: 0;
        &:after,
        &:before {
          top: 0px;
          left: 0;
          width: 34px;
        }
        &:after {
          transform: rotate(-45deg);
        }
        &:before {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.nav__item {
  button {
    background-color: $color-white;
    padding: 2rem 2.5rem;
    border: none;
    color: #773dd3;
    font-size: 2rem;
  }
}
