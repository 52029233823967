.contact {
  &_layer {
    background: rgba(229, 229, 229, 0.2);
    margin-top: 87px;

    @include respond(phone) {
      margin-top: 85px;
    }
    &-text {
      text-align: center;
      h1 {
        font-size: 3.5em;
        margin-top: 70px;
        font-family: $font-primary;

        @include respond(phone) {
          font-size: 3.5rem;
        }
      }
      p {
        font-size: 1.2em;
        color: #9da8b6;
      }
    }
    &-circles {
      &-1 {
        width: 50px;
        border-radius: 50%;
        position: absolute;
      }
    }
    img {
      width: 50px;
      border-radius: 50%;
      position: absolute;
      @include respond(tab-port) {
        display: none;
      }
    }
    img:first-child {
      top: 25%;
      left: 20%;
    }
    img:nth-child(2) {
      top: 28%;
      right: 28%;
    }
    img:nth-child(3) {
      top: 70%;
      left: 10%;
    }
    img:last-child {
      top: 80%;
      right: 10%;
    }
  }

  &_hero {
    padding: 50px 10px;
    width: 70%;
    display: block;
    margin: auto;
    @include respond(tab-port) {
      width: 80%;
    }
    @include respond(phone) {
      width: 100%;
      box-sizing: border-box;
    }
    &-flexdiv {
      @media (min-width: 700px) {
        display: flex;
      }
      &-1 {
        @media (min-width: 700px) {
          width: 50%;
        }
      }
      &-2 {
        @media (min-width: 700px) {
          margin-left: 10px;
          width: 50%;
        }
      }
    }
    label {
      color: #073041;
      font-size: 1.1em;
      margin-left: 5px;
      span {
        color: #ff570a;
      }
    }

    input {
      background: transparent;
      margin: 10px 5px 30px;
      border-radius: 10px;
      width: 81%;
      border: 1px solid #eff2f7;
      padding: 10px 15px;
      box-sizing: border-box;
      width: 100%;
    }
    textarea {
      background: transparent;
      margin: 10px 5px 30px;
      border-radius: 10px;
      width: 81%;
      border: 1px solid #eff2f7;
      padding: 10px 15px;
      height: 150px;
      box-sizing: border-box;
      width: 100%;
    }
    &-privacy {
      .download-brochure {
        color: white;
        margin: 15px 0 30px;
        background: #773dd3;
        border: 2px solid #773dd3;
        padding: 10px 20px;
        font-size: 1.1em;
        border-radius: 10px;
      }
      p {
        color: #9da8b6;
        font-size: 1.2em;
      }
      span {
        color: #773dd3;
      }
      button {
        color: white;
        margin: 15px 0 30px;
        background: #773dd3;
        border: 2px solid #773dd3;
        padding: 10px 20px;
        font-size: 1.1em;
        border-radius: 10px;
        &:last-child {
          //for now this affects only course-detail route
          color: #773dd3;
          background: transparent;
          margin: 15px 0 30px 15px;
        }
      }
    }
    &_first {
      @media (min-width: 600px) {
        width: 50%;
      }
      h1 {
        font-size: 3.5em;
        line-height: 60px;
        font-family: $font-primary;
      }
      p {
        font-size: 1.2em;
        max-width: 400px;
      }
    }

    &_second {
      @media (min-width: 600px) {
        width: 50%;
      }
      &-btn {
        margin: 20px 0 0 10px;
        p {
          font-size: 1.2em;
          margin-top: 10px;
        }
      }
    }

    &_insecond {
      @media (min-width: 900px) {
        display: flex;
      }
    }
  }

  &_campus {
    margin: 100px 0;
    &-intro {
      h2 {
        font-family: $font-primary;
        @include respond(phone) {
          font-size: 1.7em;
        }
      }
      h4 {
        color: #773dd3;
        font-size: 0.9em;
        font-family: $font-primary;
      }
      p {
        color: #9da8b6;
        font-size: 1.2em;
      }
      &-grey-box {
        width: 400px;
        height: 250px;
        background: #c4c4c4;
        @include respond(phone) {
          width: 100%;
        }
      }
    }

    &-main {
      @media (min-width: 601px) {
        display: flex;
      }

      &-div {
        h3 {
          font-size: 1.5em;
          font-family: $font-primary;
          @include respond(phone) {
            font-size: 1.2em;
            padding-top: 20px;
          }
        }
        @media (min-width: 700px) {
          margin: 30px 10px;
        }
      }

      p {
        font-size: 1.1em;
        color: #9da8b6;
        @include respond(phone) {
          font-size: 1em;
        }
      }
      img {
        width: 30px;
        height: 30px;
        @include respond(phone) {
          width: 20px;
          height: 20px;
        }
      }
      .social {
        width: 30px;
        height: 30px;
        padding: 3px;
        border: 1px solid #e9ecf5;
        border-radius: 4px;
        margin: 0 10px 10px 0;
        display: inline-block;
        @include respond(phone) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
