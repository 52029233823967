@use "sass:math";
$html-font-size: 12px;

@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
  // @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

//MEDIA QUERY
/*
0 - 600px:     Phone
600 - 900px    Tablet portrait
900 - 1200px   Tablet landscape
1200 - 1800px  Big desktop

*/

/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      //1800px
      @content;
    }
  }
}
