#timer{
    width: fit-content;
    font-weight: 400;
    color: #FF3B30;
    // line-height: 21px;
    border: 1px solid #FF3B30 !important;
    font-size: 14px;
    border-radius: 8px;
    padding: 8px 20px;
    margin: 0;
}