.footer {
  background: $color-white;
  padding: 5rem;
  padding-top: 5rem;
  padding-bottom: 3.5rem;

  @include respond(phone) {
    padding: 4rem;
    padding-top: 6rem;
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;

    @include respond(tab-port) {
      margin-bottom: 0;
    }

    @include respond(tab-port) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 3rem;
    }

    ul {
      &:first-child {
        flex-basis: 25rem;
      }

      li {
        margin-bottom: 2rem;
      }
    }
  }

  &__list {
    list-style-type: none;
    @include respond(tab-port) {
      margin-bottom: 5rem;
    }
  }
  &__accredit {
    max-width: 178px;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    @media screen and (max-width: 900px){
      align-items: start;
    }
    @include respond(tab-port) {
      margin-bottom: 5rem;
    }
  }

  &__item {
    font-family: $font-secondary;
    font-size: 1.4rem;
    font-weight: 200;
    color: #787676;
    line-height: 32px;
    @include respond(phone) {
      line-height: 25px;
      font-size: 1.6rem;
    }

    &--head {
      font-family: $font-primary;
      font-size: 1.6rem;
      font-weight: bold;
      color: #1d1e2c;
    }
    

    a {
      text-decoration: none;
      color: #787676;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__text{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #073041;
    @media screen and (max-width: 900px){
      text-align: start;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #e5e9f2;
    margin-bottom: 3rem;

    @include respond(tab-port) {
      margin-bottom: 5rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    @include respond(tab-port) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      & > * {
        margin-bottom: 2rem;
      }
    }

    a {
      text-decoration: none;
      font-size: 1.4rem;
      color: #787676;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 1.4rem;
      color: #787676;
    }
  }

  &__icons {
    flex-basis: 15rem;
    display: flex;
    justify-content: space-between;

    @include respond(tab-port) {
      width: 15rem;
    }

    img {
      height: 2rem;
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 3rem;
    transform: translateX(-2rem);
    img {
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}
