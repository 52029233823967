.payment {
  padding: 27px 91px 160px 39px;
  h4 {
    color: var(--333333, #333);
    font-family: $font-secondary;
    font-size: 18px;
    // font-style: normal;
    font-weight: 600;
    line-height: 24.55px;
    letter-spacing: 1.62px;
  }

  &-table {
    // display: none;
    padding-top: 20px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    thead {
      border-bottom: 1px solid rgba(120, 118, 118, 0.6);
      background: rgba(241, 245, 252, 0.15);
    }

    td {
      border-bottom: 1px solid #e5e9f2;
      text-align: center;
      padding-top: 42px;
      padding-bottom: 18px;
      font-size: 16px;
      color: #909198;
    }
    th {
      border-bottom: 1px solid #e5e9f2;
      text-align: center;
      padding: 12px 8px;
      font-size: 16px;
      color: #909198;
    }
    // td {
    //   cursor: pointer;
    //     text-align: center;
    // }

    th {
      font-weight: 500;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      text-transform: capitalize;
      text-align: center;
    }
    span {
      font-size: 16px;
      text-transform: capitalize;
      text-align: center;
    }

    &-action {
      background-color: white;
      border: 1px solid #773dd3;
      position: absolute;
      padding: 10px 10px 0;
      z-index: 1;
    }

    .approved {
      padding: 2px 10px;
      width: 100px;
      color: #188f32;
      //   font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .pending {
      padding: 2px 10px;
      width: 100px;
      color: #cc9600;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .paid {
      width: 93px;
      margin: auto;
      color: #787676;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      padding: 2px 8px;
      line-height: 18px; /* 150% */
      border-radius: 100px;
      border: 2px solid #fff;
      background: #E9E9EA;
      pointer-events: none;
    }
    .pay-now {
      width: 93px;
      margin: auto;
      color: #773dd3;
      text-align: center;
      font-size: 12px;
      padding: 2px 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 150% */
      border-radius: 100px;
      border: 2px solid #fff;
      background: rgba(220, 205, 244, 0.5);
      cursor: pointer;
    }
  }
  &-mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
    &-container {
      margin-top: 32px;
      .details {
        span {
          color: var(--text-color, #333);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        color: var(--Grey, #909198);
        margin-bottom: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        .approved {
          padding: 2px 10px;
          width: 100px;
          color: #188f32;
          //   font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
    
        .pending {
          padding: 2px 10px;
          width: 100px;
          color: #cc9600;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      border-bottom: 1px solid #efefef;
    }
    

    .paid {
      width: 93px;
      margin: auto 0;
      color: #787676;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      padding: 2px 10px;
      line-height: 18px; /* 150% */
      border-radius: 100px;
      border: 2px solid #fff;
      background: #E9E9EA;
      margin-bottom: 8px;
      // cursor: pointer;
      pointer-events: none;

    }
    .pay-now {
      width: 93px;
      margin: auto 0;
      color: #773dd3;
      text-align: center;
      font-size: 12px;
      padding: 2px 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 150% */
      border-radius: 100px;
      border: 2px solid #fff;
      background: rgba(220, 205, 244, 0.5);
      margin-bottom: 8px;
      cursor: pointer;

    }
  }
}
