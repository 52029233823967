.accordion-button{
  width: 100%;
  border: none;
  background: white;
  text-align: start;
}
.loader {
  left: 50%;
  position: absolute;
  width: 40px;
}

.loader-top {
  left: 50%;
  top: 50%;
  position: fixed;
  width: 40px;
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.blur-active {
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
  pointer-events: none;
  user-select: none;
}

.faq-icon_transition {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.landing {
  &__intern {
    background-color: $color-skyblue;
    margin-bottom: 10rem;

    &--btn {
      margin: 0 auto;
      display: flex;
      margin-left: 20px;
    }

    &--text {
      font-size: 6rem;
      color: #1f1035;
      font-weight: normal;
      font-family: Georgia;
      margin: 2rem 0;

      h1 {
        width: 70%;
        @include respond(phone) {
          width: 100%;
        }
      }
    }

    &--content {
      padding: 2rem;
      padding-bottom: 7rem;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__header {
    background-color: $color-white;
    padding: 1rem;
    padding-bottom: 8rem;
    position: relative;

    .bg-img {
      position: absolute;
      right: 0;
      top: 0;
      height: 50rem;
    }

    .st-btn {
      width: calc(97.29px + 40px);
      margin: auto;
    }
  }

  &__text {
    max-width: 900px; 
    margin: 0 auto;
    padding: 2rem 1rem;
    padding-bottom: 1.5rem;
    text-align: center;

    &-below{
      display: flex;
      justify-content: center;
      margin-top: 30px;
      @media(max-width:600px){
        display: block;
        margin: 30px auto 0;
        width: 250px;
      }
      div{
        display: flex;
        img{
          margin: 0 10px 0 40px;
          @media(max-width:600px){
            margin: 0 10px 0 0;
          }

        }
        h6{
          font-size: 16px;
        }
      }
    }

    h1 {
      font-family: $font-primary;
      color: $color-excellent-purple;
      font-weight: 700;
      font-size: 6rem;
      margin-top: 8rem;

      span {
        color: $color-inspiring-purple;
      }

      @include respond(tab-port) {
        font-size: 5rem;
      }

      @include respond(phone) {
        font-size: 3.5rem;
        margin-top: 12rem;
      }
    }

    p {
      font-family: $font-secondary;
      font-size: 2rem;
      margin: 3rem 0;
      color: #9da8b6;
      line-height: 30px;
      font-weight: normal;

      @include respond(phone) {
        margin: 2rem 0;
        line-height: 25px;
        font-size: 1.8rem;
      }
    }
  }

  &__btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    &--2 {
      margin-right: auto;
      width: fit-content;
    }
    &--3 {
      margin-left: auto;
    }
  }

  &__bottom {
    padding: 14rem 5rem;
    max-width: 1200px;
    margin: 0 auto 6rem;
    @include respond(phone) {
      padding: 2rem;
    }
    &--text1 {
      align-items: center;
      font-family: $font-secondary;
      margin:0 25px;
      @media(min-width:900px){
        width: 500px;
        margin-left: 15rem;
      }
      h1{
        font-family: $font-primary;
      }
      p{
        font-size: 16px;
        color: #787676;
      }
    }

    &--text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $font-secondary;
      @include respond(phone) {
        display: block;
      }
      h2{
        font-family: $font-primary;
        width: 100vw;
        text-align: center !important;
      }

      &-2 {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 2rem;
        p {
          color: $color-light-grey;
        }
      }

      &2 {
        @include respond(tab-port) {
          width: 60%;
        }
        @include respond(phone) {
          width: 90%;
        }
      }

      @include respond(phone) {
        flex-direction: column-reverse;
        align-items: initial;
      }

      &--right {
        flex-basis: 49%;

        @include respond(tab-port) {
          flex-basis: initial;
        }
        @include respond(phone) {
          margin-top: 40px;
        }
      }

      &--left {
        text-align: center;
        img {
          height: auto;
          width: 100%;

          @include respond(tab-port) {
            width: 90%;
          }
        }
      }

      h5 {
        font-size: 1.4rem;
        color: $color-base-purple;
        margin-top: 2rem;
      }

      h1 {
        font-size: 5rem;
        color: $color-excellent-purple;
        font-weight: normal;
        font-family: $font-primary;
        margin: 2rem 0;

        @include respond(tab-port) {
          font-size: 4rem;
        }

        @include respond(phone) {
          font-size: 3rem;
        }
      }

      p {
        font-size: 16px;
        line-height: 3rem;
        margin-bottom: 2rem;
        color: #787676;
      }
    }

    &--form {
      background-color: $color-inspiring-purple;
      margin-top: 8rem;
      border-radius: 10px;
      text-align: center;
      padding: 10rem;
      position: relative;
      @include respond(phone) {
        border-radius: 5px;
        margin-bottom: 6rem;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;

        @include respond(phone) {
          width: 8rem;
        }
      }

      @include respond(tab-port) {
        padding: 6rem;
      }
      @include respond(phone) {
        padding: 2rem;
      }

      h2 {
        font-size: 3.7rem;
        color: $color-white;
        font-family: $font-primary;
        margin-bottom: 3rem;
        font-weight: normal;

        @include respond(phone) {
          font-size: 2.2rem;
        }
      }

      form {
        input {
          background-color: transparent;
          border: 1px solid #fff;
          padding: 0 1.2em;
          font-size: 1.4rem;
          color: $color-white;
          outline: none;
          border-radius: 3px;
          width: 374px;
          height: 58px;
          font-family: $font-secondary;
          font-weight: normal;
          margin-right: 1rem;
          margin-bottom: 2rem;

          @include respond(tab-port) {
            width: 300px;
          }

          @include respond(phone) {
            width: 50%;
            height: initial;
            padding: 1.8rem;
          }

          &::placeholder {
            color: $color-white;
          }
        }

        button {
          background-color: $color-white;
          border: 1px solid #fff;
          border-radius: 3px;
          padding: 0 1.5rem;
          color: #773dd3;
          font-size: 1.4rem;
          font-family: $font-primary;
          height: 58px;
          outline: none;
          cursor: pointer;

          @include respond(phone) {
            height: initial;
            padding: 1.8rem;
          }

          &:hover {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  &_faq {
    margin-bottom: 100px;
    font-family: Georgia;
    h1 {
      font-weight: 700;
      font-family: $font-primary;
      margin: 4rem 0;
      color: #073041;
      // font-family: Georgia;
      font-size: 40px;
      line-height: 45.45px;
      text-align: center;
    }
    h3 {
      margin: 40px 0 20px;
      color: $color-inspiring-purple;
    }

    h4 {
      color: #1f1035;
      font-size: 20px !important;
      font-family: $font-secondary;
      font-weight: 600;

      @media (max-width:500px){
        font-size: 16px !important;
      }
    }

    p {
      // font-size: 16px;
      font-size: 1.4rem;
      color: #787676;
      line-height: 35px;
    }
    hr{
      margin: 0px 0 20px;
    }
    .faq-number{
      color: #2196F3;
    }
    .faq-whatsapp{
      color: #773DD3;
    }
    &-link {
      color: $color-inspiring-purple;
    }
    &-question {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-answer {
      padding: 10px 0;
      background: #FBFCFF;
      border-radius: 8px;
      padding: 10px 10px 20px 10px;

      // background-color: rgba(128, 128, 128, 0.3);
      h6{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color-formgrey;
        margin: 0;
      }
      p{
        line-height: 24px;
      }
    }
  }

  &__slider {
    max-width: 100vw;
    &-text1{
      display: block;
      margin: auto;
      max-width: 900px;
      p{
        color: #787676;
        text-align: center;
        margin: 0 20px 25px 0;
      }
    }

    &-contain{
      width: 100vw;
      position: relative;
    }
    &--container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex !important;
      overflow-x: scroll;
      padding: 5rem;
      // position: relative;
      &::-webkit-scrollbar {
        display: none;
      }

      .scroll-left{
        position: absolute;
        top: 170px;
        left: 30px;
        transform: rotate(-90deg);
        background: white;
        border-radius: 50%;
        cursor: pointer;
        @media(max-width:1000px){
          width: 40px;
          left: 15px;
        }
        @media(max-width:700px){
          display: none;
        }
      }

      .scroll-right{
        position: absolute;
        background: white;
        border-radius: 50%;
        top: 170px;
        right: 30px;
        transform: rotate(90deg);
        cursor: pointer;
        @media(max-width:1000px){
          width: 40px;
          right: 15px;
        }
        @media(max-width:700px){
          display: none;
        }
      }

      .video-width{
        box-sizing: border-box;
        width: 560px;
        border-radius: 20px;
        height: 315px;
        margin-right: 30px;
        @media(max-width:700px){
          width: 320px;
        height: 200px;
        } 
      }

      // @include respond(phone) {
      //   margin-bottom: 5rem;
      //   padding-left: 3rem;
      // }
    }

    &-desktop{
      @media(max-width:900px){
        display:none;
      }
    }

    &-mobile{
      padding:70px 0;
      @media(min-width:900px){
        display:none;
      }

      iframe{
        width:90%;
        height: 50vw;
        margin: 5vw auto;
        display: block;
      }
    }

    &--text {
      flex-basis: 50%;
      font-family: $font-secondary;
      @include respond(tab-port) {
        flex-basis: 55%;
      }
      @include respond(phone) {
        flex-basis: 90%;
        margin: 0 60px 0 20px;
      }

      p {
        font-size: 2rem;
        line-height: 40px;
        color: #183b56;
        margin-bottom: 2rem;
        font-weight: 400;
        margin-top: 2rem;
        animation: fade-in 1s;

        @include respond(tab-port) {
          line-height: 30px;
        }
      }

      small {
        display: block;
        font-size: 1.4rem;
        margin-bottom: 3rem;
        color: #183b56;
        font-weight: 400;
      }
    }

    background-color: $color-skyblue;

    &--img {
      border-radius: 9999px;
      background-color: $color-white;
      box-shadow: 0px 10px 20px rgba(24, 59, 86, 0.1);
      height: 370px;
      width: 370px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(tab-port) {
        height: 200px;
        width: 200px;
      }

      @include respond(phone) {
        display: none;
      }

      img {
        height: 350px;
        width: 350px;
        border-radius: 9999px;

        @include respond(tab-port) {
          height: 200px;
          width: 200px;
        }
      }
    }
  }

  &__dots {
    div {
      display: inline-block;
      margin-right: 1rem;
      cursor: pointer;
      background-color: rgba(115, 135, 150, 0.5);
      height: 9px;
      width: 9px;
      border-radius: 99999px;
    }

    &--0 {
      div {
        &:nth-child(1) {
          background-color: #183b56;
        }
      }
    }
    &--1 {
      div {
        &:nth-child(2) {
          background-color: #183b56;
        }
      }
    }
    &--2 {
      div {
        &:nth-child(3) {
          background-color: #183b56;
        }
      }
    }
    &--3 {
      div {
        &:nth-child(4) {
          background-color: #183b56;
        }
      }
    }
  }

  &__cards {
    margin: 8rem 0;
    padding: 3rem;
    @include respond(tab-port) {
      margin: 0;
    }

    &--container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__courses {
    margin: 10rem 0;
    margin-bottom: 0;
    padding: 6rem 3rem;
    background-color: #eff9ff;
    margin-top: 0;

    &--container {
      &--txt {
        max-width: 1150px;
        margin: 0 auto;
        padding: 0 2rem;
        @include respond(phone) {
          flex-direction: column;
          align-items: initial;
          padding: 0;
        }
      }
    }

    &--cards {
      max-width: 1150px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 1.5rem;
      margin-top: 9rem;
      @include respond(tab-port) {
        grid-template-columns: 1fr 1fr;
      }
      @include respond(phone) {
        display: block;
      }
    }

    &--card {
      background-color: #fff;
      text-align: center;
      position: relative;
      margin-bottom: 9rem;
      padding: 2rem;
      padding-top: 0;
      border-radius: 10px;

      h3 {
        font-family: $font-primary;
        font-size: 2rem;
        color: #1f1035;
        margin-top: 1rem;
        font-weight: bold;
      }

      p {
        color: #9da8b6;
        font-family: $font-secondary;
        font-size: 1.4rem;
        margin: 2rem 0;
        margin-bottom: 3rem;
        line-height: 28px;
      }

      a {
        text-decoration: none;
        color: #773dd3;
        font-family: $font-primary;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        transition: all 0.2s;
        &:hover {
          text-decoration: underline;
        }
      }

      div {
        width: 100%;
        height: 200px;
        background-color: #62b8f3;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-4rem);
        border-radius: 10px;
      }
    }

    &--container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__card--box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;

    @include respond(tab-port) {
      display: block;
    }
  }

  &__card {
    text-align: center;
    padding: 5rem;
    padding-top: 7rem;
    border-radius: 22px;
    margin-bottom: 3rem;

    @include respond(phone) {
      border-radius: 5px;
      padding: 3rem;
      padding-bottom: 5rem;
    }

    h3 {
      font-size: 4rem;
      font-family: $font-primary;

      span {
        color: $color-creative-yellow;
      }
    }

    p {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: 1.6rem;
      text-align: center;
      margin: 1.5rem 0;
      color: $color-grey;
      line-height: 32px;

      @include respond(phone) {
        line-height: 25px;
      }
    }

    &--1 {
      background-color: #fff6e5;
    }

    &--2 {
      background-color: $color-pale-purple;
      h3 {
        span {
          color: #682dc8;
        }
      }
    }
  }

  &__sponsors {
    width: 100%;
    overflow: hidden;
    &--container {
      max-width: 1200px;
      margin: 6rem auto;
      display: flex;
      justify-content: space-between;
      padding: 0 2rem;

      @include respond(phone) {
        margin: 3rem 0;
      }

      img {
        height: 35px;
        @include respond(phone) {
          height: 15px;
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__middle {
    padding: 10rem 2rem;

    &--container {
      max-width: 1200px;
      margin: 0 auto;
    }

    &--text {
      text-align: center;

      h5 {
        font-size: 1.6rem;
        font-family: $font-secondary;
        color: $color-base-purple;
        margin-bottom: 2rem;
      }
      h2 {
        color: $color-excellent-purple;
        font-size: 5rem;
        font-family: $font-primary;
        margin-bottom: 2rem;

        @include respond(phone) {
          font-size: 3rem;
        }
      }
      p {
        color: #9da8b6;
        font-size: 1.8rem;
        line-height: 35px;
      }
    }

    &--images {
      text-align: center;
      img {
        border-radius: 99999px;
      }
      a {
        color: #1f1035;
        font-size: 1.8rem;
        font-weight: bold;
        font-family: $font-secondary;
        margin-top: 1.5rem;
        display: block;
        transition: all 0.2s;

        &:hover {
          text-decoration: underline;
        }
      }
      h5 {
        font-size: 1.2rem;
        color: #1f1035;
        font-weight: 600;
        font-family: $font-secondary;
        margin: 1rem 0;
      }
      small {
        display: block;
        color: #9da8b6;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      svg {
        display: block;
        margin: 0 auto;
      }
      &--box {
        margin-top: 5rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 5rem;
        @include respond(tab-port) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  &__video {
    video {
      height: 100%;
      width: 100%;
      outline: none;

      @include respond(phone) {
        height: 100%;
      }
    }
  }

  &__discount {
    background-color: rgba(254, 244, 191, 0.4);
    margin-top: 0rem;
    position: relative;
    @include respond(phone) {
      margin-top: 0;
    }

    &--container {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      padding: 6rem 2rem;
      @include respond(phone) {
        display: block;
      }
    }

    &--img {
      @include respond(phone) {
        display: none;
      }
      img {
        border-radius: 20px;
        height: auto;
        width: 400px;
        position: absolute;
        top: -5rem;
        @include respond(tab-port) {
          height: auto;
          width: 270px;
          position: initial;
          float: left;
        }
      }
    }

    &--text {
      flex-basis: 30%;
      align-self: center;
      margin-left: 50rem;
      @include respond(tab-port) {
        margin-left: 3rem;
        flex-basis: 40%;
      }

      h3 {
        color: #073041;
        font-size: 3rem;
        font-family: $font-primary;
        font-weight: bold;
        line-height: 48px;
        margin: 0;
        @include respond(tab-port) {
          line-height: initial;
        }
      }

      p {
        margin: 1rem 0;
        color: $color-light-grey;
        line-height: 32px;
        font-size: 1.6rem;
        font-family: $font-secondary;
        @include respond(tab-port) {
          line-height: initial;
          margin: 2rem 0;
        }
      }
    }
  }

  &__discover {
    &--container {
      max-width: 1200px;
      margin: 5rem auto;
      padding: 0 2rem;
      margin-bottom: 15rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(tab-land) {
        margin-left: 3rem;
      }
      @include respond(phone) {
        display: block;
        margin-left: 0;
        margin-bottom: 5rem;
        margin-top: 2rem;
      }
    }
    &--images {
      display: flex;
      @include respond(phone) {
        display: none;
      }

      &-left {
        div {
          border-radius: 10px;
          margin-bottom: 1.5rem;
          background-size: cover;
          background-repeat: no-repeat;
          &:first-child {
            height: 190px;
            width: 180px;
            background-image: url(../images/Grazac-website/IMG_8350.JPG);
            background-position: left -40px bottom 0px;
            margin-left: auto;
            @include respond(tab-land) {
              height: 150px;
              width: 130px;
            }
          }
          &:last-child {
            height: 320px;
            width: 250px;
            background-image: url(../images/Grazac-website/IMG_8304.JPG);
            background-position: right 0px bottom 0px;
            @include respond(tab-land) {
              height: 260px;
              width: 210px;
            }
          }
        }
      }
      &-right {
        div {
          height: 210px;
          width: 190px;
          border-radius: 10px;
          margin-left: 1.5rem;
          // margin-top: 10rem;
          background-image: url(../images/Grazac-website/IMG_8324.JPG);
          background-position: left 0px bottom 0px;
          background-size: cover;
          background-repeat: no-repeat;
          @include respond(tab-land) {
            display: none;
          }
        }
      }
    }
    &--list {
      flex-basis: 50%;

      &-item {
        display: flex;
        div {
          margin-left: 5rem;
        }
        h2 {
          color: $color-light-grey;
          font-size: 2.4rem;
          font-weight: normal;
        }
        h3 {
          color: #1f1035;
          font-family: Georgia;
        }
        p {
          font-size: 1.6rem;
          color: #787676;
          line-height: 35px;
          @include respond(phone) {
            line-height: 30px;
          }
        }
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}