.main {
  height: 100vh;
  width: 100%;
  background: #cecece;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 20000000;
  overflow-y: hidden;
}
.main main {
  width: 70%;
  min-height: 400px;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  display: grid;
  place-items: center;
}
.main main .close {
  width: fit-content;
  display: grid;
  place-items: center;
  border: none;
  outline: none;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  transition: 0.5s ease-out;
}
.main main .close:hover {
  transform: scale(1.1);
}

.main main .close svg {
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 1000px) {
  .main > main {
    width: 85%;
  }
}

