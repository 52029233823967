.about {
  &__nav {
    background-color: #fff;
    margin-top: 100px;
    @include respond(tab-port) {
      margin-top: 80px;
    }
    @include respond(phone) {
      margin-top: 15rem;
    }
  }

  &__top {
    margin-top: 5rem;
    @include respond(phone) {
      margin-top: 7rem;
    }
    &--container {
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 2rem;
    }

    &--up {
      display: flex;
      align-items: center;
      @include respond(phone) {
        display: block;
      }
      div {
        border-radius: 5px;
        background-size: cover;
        background-repeat: no-repeat;

        &:first-child {
          width: 24rem;
          height: 26rem;
          background-image: url(../images/Grazac-website/IMG_8350.JPG);
          background-position: left -60px bottom 0px;
          align-self: flex-end;
          position: relative;
          @include respond(phone) {
            display: none;
          }
          img {
            position: absolute;
            left: -7rem;
            top: -4rem;
            z-index: -100;
          }
        }
        &:nth-child(2) {
          height: 31.5rem;
          width: 33rem;
          background-image: url(../images/Grazac-website/IMG_8304.JPG);
          background-position: right -30px bottom 0px;
          margin-left: 2rem;
          @include respond(phone) {
            display: none;
          }
        }
      }
    }

    &--down {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      div {
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;

        &:first-child {
          width: 38rem;
          height: 29rem;
          background-image: url(../images/Grazac-website/IMG_8297.JPG);
        }
        &:last-child {
          width: 46rem;
          height: 35rem;
          margin-left: 2rem;
          background-image: url(../images/Grazac-website/IMG_8324.JPG);
        }
      }
    }

    &--text {
      display: block;
      flex-basis: 35%;
      margin: 0 auto;
      h1 {
        font-size: 5rem;
        color: #073041;
        font-family: $font-primary;
        font-weight: bold;

        @include respond(phone) {
          font-size: 3.5rem;
        }
      }
      p {
        font-size: 1.8rem;
        color: $color-light-grey;
        line-height: 30px;
        @include respond(phone) {
          width: 80%;
          margin-bottom: 5rem;
        }
      }
    }
  }

  &__middle {
    padding: 8rem 2rem 10rem;
    background-color: $color-skyblue;

    &--container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
    }

    &--cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 4rem;
      margin-top: 3rem;
      @include respond(phone) {
        display: block;
      }
    }

    &--card {
      background-color: $color-white;
      border-radius: 5px;
      padding: 3rem 2rem;
      @include respond(phone) {
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }

      h3 {
        color: #073041;
        width: 80%;
        font-size: 2rem;
        font-weight: bold;
        font-family: $font-primary;
        line-height: 140%;
        @include respond(phone) {
          width: 60%;
          line-height: 30px;
        }
      }

      p {
        line-height: 30px;
        font-size: 1.4rem;
        font-family: $font-secondary;
        color: $color-light-grey;
        @include respond(phone) {
          font-size: 1.6rem;
          width: 95%;
        }
      }

      div {
        width: 10rem;
        height: 10rem;
        border-radius: 99999px;
        background-color: rgba(var(--bg-color), 0.06);
        position: relative;

        &::after {
          width: 6rem;
          height: 6rem;
          border-radius: 99999px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(var(--bg-color), 1);
          content: "";
          display: table;
        }
      }
    }

    &--head {
      width: 35rem;
      h4 {
        color: #773dd3;
        font-size: 1.4rem;
        font-family: $font-secondary;
        font-weight: normal;
      }
      h2 {
        font-size: 3rem;
        color: #073041;
        font-family: $font-primary;
        font-weight: bold;
      }
      p {
        color: $color-light-grey;
        font-size: 1.6rem;
        line-height: 30px;
      }
    }
  }
}
