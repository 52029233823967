//For Desktop responsiveness view
$desktop: 1201px;
//For Tablet responsiveness view
$tablet: 840px;
//For Mobile responsiveness view
$mobile: 839px;

// Colors
$color-white: #ffffff;
$color-skyblue: #f1f5fc;
$color-purple-text: #1f1035;
$color-inspiring-purple: #773dd3;
$color-white: #fff;
$color-collaborative-purple: #cfb9f9;
$color-grey: #575a65;
$color-formgrey: #787676;
$color-excellent-purple: #1f1035;
$color-creative-yellow: #e7a300;
$color-collaborative-yellow: #fad47a;
$color-base-purple: #5726a6;
$color-pale-purple: #dccdf4;
$color-cream: #fef4bf;
$color-light-grey: #9da8b6;

//fonts
$font-primary: Georgia;
$font-secondary: "Nunito Sans", sans-serif;
